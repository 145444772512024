
import React, { useEffect, useState } from 'react'
import { AppMisc } from '../../../misc/appMisc';
import { useForm } from 'react-hook-form';
import AHMIS, { API_URL } from '../../../api/api';
import { ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, YOGSEWA } from '../../../utils/constants';
import AddAlertMessage from '../../../components/alert/Alert';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { INVALID_BLOOD_PRESSURE_MESSAGE, MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import { Box, Grid, TextField, Typography } from '@mui/material';
import RegisterHeader from '../../../components/register-header/RegisterHeader';
import { DateUtils } from '../../../utils/dateUtils';
import CustomModal from '../../../components/modal/CustomModal';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import YogSewaRegister from '../../users/components/register/yog-sewa-register/YogSewaRegister';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import PendingPatientList from '../../../components/pending-patient-list/PendingPatientList';

const YogSewaForm = () => {

  const [modalTitle, setModalTitle] = useState("योग सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showYogSewaModal, setShowYogSewaModal] = useState(false);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [yogSewaTableData, setYogSewaTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [showYogSewaDeleteModal, setShowYogSewaDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [yogSewaDeleteId, setYogSewaDeleteId] = useState(null);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [pendingPatientList, setPendingPatientList] = useState([])


  useEffect(() => {
    showYogSewaModal && reset();
  }, [reset, showYogSewaModal]);

  const openYogSewaModal = () => {
    setShowYogSewaModal(true);
  };

  const closeYogSewaModal = () => {
    setShowYogSewaModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const submitYogSewaRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.yogSewaRegister, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeYogSewaModal();
          setGetTableData(true);
          yogSewaPendingPatient()
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getYogSewaData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.yogSewaRegister}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setYogSewaTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const yogSewaEdit = (id) => {
    AHMIS.get(API_URL.yogSewaRegister + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowYogSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeYogSewaDeleteModal = () => {
    setShowYogSewaDeleteModal(false);
    setYogSewaDeleteId(null);
  }

  const onDeleteYogSewaRow = (id) => {
    setShowYogSewaDeleteModal(true);
    setYogSewaDeleteId(id);
  }

  const deleteYogSewaRow = (data) => {
    AHMIS.delete(API_URL.yogSewaRegister + "/" + yogSewaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowYogSewaDeleteModal(false);
          setYogSewaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getYogSewaData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=4&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  useEffect(() => {
    yogSewaPendingPatient()
  }, [])

  const yogSewaPendingPatient = () => {
    let url = `${API_URL.pendingPatient}?sewaType=${"7"}&fiscalYear=${DateUtils.getCurrentFiscalYear()}&registerConstant=${YOGSEWA}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setPendingPatientList(jsondata.data);
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="योग सेवा रजिष्टर"
          handleModalOpen={openYogSewaModal}
          getRegisterData={getYogSewaData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showYogSewaModal}
        onModalSubmit={handleSubmit(submitYogSewaRegister)}
        onModalClose={closeYogSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
            registerConstant={YOGSEWA}
          />
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवा दर्ता नम्बर"
              type="number"
              size="small"
              variant="outlined"
              name="sewaDartaaNumber"
              fullWidth
              defaultValue={modalDefaultValues?.sewaDartaaNumber}
              {...register("sewaDartaaNumber", { required: true })}
            />
            {errors?.sewaDartaaNumber && errors.sewaDartaaNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रक्तचाप (Blood Pressure)"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure", {
                pattern: /^(0|[1-9][0-9]*)\/(0|[1-9][0-9]*)$/,
                required: true
              })}
            />
            {errors.bloodPressure && errors.bloodPressure.type === "pattern" && <span className="error-message">{INVALID_BLOOD_PRESSURE_MESSAGE}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature", { required: true })}
            />
            {errors?.nature && errors.nature?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल (KG)"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight", { required: true })}
            />
            {errors?.weight && errors.weight?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रोग"
              size="small"
              variant="outlined"
              name="disease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.disease}
              {...register("disease", { required: true })}
            />
            {errors?.disease && errors.disease?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="FBS"
              size="small"
              variant="outlined"
              name="fbs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("fbs", { required: true })}
            />
            {errors?.fbs && errors.fbs?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="test"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.test}
              {...register("test", { required: true })}
            />
            {errors?.test && errors.test?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        </Grid>
      </CustomModal>
      {
        pendingPatientList?.size > 0 &&
        <Box mt={2}>
          <PendingPatientList
            pendingPatientList={pendingPatientList}
            showModal={setShowYogSewaModal}
            modalDefaultValues={setModalDefaultValues}
            setValue={setValue}
          />
        </Box>
      }
      <DeleteRegisterRecordConformation
        showDeleteModal={showYogSewaDeleteModal}
        closeDeleteModel={closeYogSewaDeleteModal}
        deleteRecord={deleteYogSewaRow}
      />
      <YogSewaRegister
        onEditRow={yogSewaEdit}
        tableData={yogSewaTableData}
        deleteRow={onDeleteYogSewaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default YogSewaForm;