import { CreditCard, Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { CASTE_CODES, FEMALE, GENDER_OPTIONS, MALE, MEDICINE_NAME, NO_RECORDS_FOUND } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { AppMisc } from "../../../../../misc/appMisc";
import { NEW_PATIENT, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";
import InfiniteScroll from "react-infinite-scroll-component";

const MedicineDistributionRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props?.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props?.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register" ref={tableRef}>
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>मासिक क्र.सं</TableCell>
                <TableCell rowSpan={2}>मूल दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={2}>जाती कोड</TableCell>
                </Tooltip>
                <TableCell colSpan={2}>उमेर</TableCell>
                <TableCell colSpan={5}>ठेगाना</TableCell>
                <TableCell rowSpan={2}>राष्ट्रिय परिचयपत्र नम्वर</TableCell>
                <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
                <TableCell colSpan={4}>अविपत्तिकर चुर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>हिङग्वाष्टक चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>महायोगराज गुग्गुलु १० ग्राम</TableCell>
                <TableCell colSpan={4}>महानारायण तेल ६० एम. एल.</TableCell>
                <TableCell colSpan={4}>सितोपलादि चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>टंकण भष्म १० ग्राम</TableCell>
                <TableCell colSpan={4}>पुष्यानुग चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>बालामृत १०० एम एल.</TableCell>
                <TableCell colSpan={4}>मृगमदासव १५ एम. एल.</TableCell>
                <TableCell colSpan={4}>सर्पगन्धाघन वटि २५ ग्राम</TableCell>
                <TableCell colSpan={4}>निम्बादी चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>गन्धक रसायन १० ग्राम</TableCell>
                <TableCell colSpan={4}>खदिरादी वटी १० ग्राम</TableCell>
                <TableCell colSpan={4}>षडबिन्दु तेल १० एम. एल.</TableCell>
                <TableCell colSpan={4}>योगराज गुग्गुलु २५ ग्राम</TableCell>
                <TableCell colSpan={4}>महासुदर्शन चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>महाशंख वटी   १० ग्राम</TableCell>
                <TableCell colSpan={4}>बिल्वादी चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>जात्यादी तेल ६० एम. एल.</TableCell>
                <TableCell colSpan={4}>कैशोर गुग्गुलु २५ ग्राम</TableCell>
                <TableCell colSpan={4}>चन्द्रप्रभावटी २५ ग्राम</TableCell>
                <TableCell colSpan={4}>त्रिफला चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>अश्वगन्धा चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>आमलकी चूर्ण ५० ग्राम</TableCell>
                <TableCell colSpan={4}>दशमुल तेल ६० एम. एल.</TableCell>
                <TableCell colSpan={4}>शतावरी चूर्ण १०० ग्राम</TableCell>
                <TableCell colSpan={4}>ज्वानो -यवानी चूर्ण_ १०० ग्राम</TableCell>
                <TableCell rowSpan={2}>सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पहिलो पटक</TableCell>
                <TableCell>थप पटक</TableCell>
                <TableCell>नाम</TableCell>
                <TableCell>थर</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>पुरुष</TableCell>
                <TableCell>जिल्ला</TableCell>
                <TableCell>नगर/गाउँपालिका</TableCell>
                <TableCell>वडा नं.</TableCell>
                <TableCell>टोल</TableCell>
                <TableCell>अन्य मुलुक</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
                <TableCell>अल्या</TableCell>
                <TableCell>प्राप्त</TableCell>
                <TableCell>खर्च</TableCell>
                <TableCell>बाँकी</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {
                mainRegisterData?.length > 0 ? (
                  mainRegisterData?.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                      <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                      <TableCell>{row?.patientType === NEW_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row?.dartaaNumber) : "-"}</TableCell>
                      <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.dartaaNumber && AppUtils.replaceWithNepaliDigit(row?.dartaaNumber) : "-"}</TableCell>
                      <TableCell>{row?.patientFirstName && row?.patientFirstName}</TableCell>
                      <TableCell>{row?.patientLastName && row?.patientLastName}</TableCell>
                      <TableCell>{row.casteCode || "-"}</TableCell>
                      <TableCell>{row?.gender === FEMALE ? AppMisc.findLabelFromValue(GENDER_OPTIONS, row.age) : "-"}</TableCell>
                      <TableCell>{row?.gender === MALE ? AppMisc.findLabelFromValue(GENDER_OPTIONS, row.age) : "-"}</TableCell>
                      <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                      <TableCell>{row?.palikaName && AppMisc.getMunicipalityName(row?.palikaName)}</TableCell>
                      <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                      <TableCell>{row?.gaunOrTole || "-"}</TableCell>
                      <TableCell>{row?.otherCountry || "-"}</TableCell>
                      <TableCell>{row?.nationalCardNumber || "-"}</TableCell>
                      <TableCell>{row?.phoneNumber && AppUtils.replaceWithNepaliDigit(row?.phoneNumber)}</TableCell>
                      <TableCell>{AppMisc.findLabelFromValue(MEDICINE_NAME, row.alya)}</TableCell>
                      <TableCell>{AppMisc.findLabelFromValue(MEDICINE_NAME, row.prapta)}</TableCell>
                      <TableCell>{AppMisc.findLabelFromValue(MEDICINE_NAME, row.kharchha)}</TableCell>
                      <TableCell>{AppMisc.findLabelFromValue(MEDICINE_NAME, row.baki)}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <IconButton aria-label="edit">
                            <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                          </IconButton>
                          <Tooltip title="स्वास्थ्य सेवा कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <CreditCard fontSize="small" />
                          </Tooltip>
                          <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                            <IconButton >
                              <Delete fontSize="small" color="secondary" onClick={() => props.onDeleteRow(row.id)} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="150" size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default MedicineDistributionRegister
