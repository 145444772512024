import { Box, Grid, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import NepaliCalender from "../../../components/nepali-calender"
import JesthaNagarikaSewaRegister from "../../users/components/register/jestha-nagarika-sewa-register/JesthaNagarikaSewaRegister"
import { useForm } from "react-hook-form"
import { CASTE_CODES, ERROR, GENDER_OPTIONS, JESTHANAGARIK, JESTHANAGARIK_SEWA, PATIENT_TYPES, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import { AGE_UNITS, MUL_DARTA_NUMBERS_LIST } from "../../../utils/constants/forms"
import { AppMisc } from "../../../misc/appMisc"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"
import MulDartaaSelect from "../../../components/mul-dartaa-select/MulDartaaSelect"
import { SessionStorage } from "../../../utils/storage/sessionStorage"
import { DateUtils } from "../../../utils/dateUtils"
import PendingPatientList from "../../../components/pending-patient-list/PendingPatientList"

const JesthaNagarikaSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("जेष्ठ नागरिक सेवा  रजिष्टर")
  const [showJesthaNagarikaSewaModal, setJesthaNagarikaSewaModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [getTableData, setGetTableData] = useState(false);
  const [JesthaNagarikaSewaTableData, setJesthaNagarikaSewaTableData] = useState([]);
  const [showJesthaNigrikDeleteModal, setShowJesthaNigrikDeleteModal] = useState(false);
  const districtOptions = AppMisc.getDistrictOptions();
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [jesthaNigrikDeleteId, setJesthaNigrikDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [pendingPatientList, setPendingPatientList] = useState([])

  useEffect(() => {
    register("jesthaNagarikSewa");
  }, [register]);

  useEffect(() => {
    showJesthaNagarikaSewaModal && reset();
  }, [reset, showJesthaNagarikaSewaModal])

  const openJesthaNagarikaSewaModal = () => {
    setJesthaNagarikaSewaModal(true);
  }

  const closeJesthaNagarikaSewaModal = () => {
    setJesthaNagarikaSewaModal(false);
    setModalDefaultValues();
    reset();
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const submitJesthaNagarikaSewaRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.jesthanagarik, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeJesthaNagarikaSewaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getJesthaNagarikaSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.jesthanagarik}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setJesthaNagarikaSewaTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const jesthaNagrikSewaEdit = (id) => {
    AHMIS.get(API_URL.jesthanagarik + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setJesthaNagarikaSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }
  const closeJesthaNigrikDeleteModal = () => {
    setShowJesthaNigrikDeleteModal(false)
    setJesthaNigrikDeleteId(null);
  }

  const onDeleteJesthaNigrikRow = (id) => {
    setShowJesthaNigrikDeleteModal(true)
    setJesthaNigrikDeleteId(id);
  }

  const deleteJesthaNigrikRow = (data) => {
    AHMIS.delete(API_URL.jesthanagarik + "/" + jesthaNigrikDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowJesthaNigrikDeleteModal(false);
          setJesthaNigrikDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getJesthaNagarikaSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  useEffect(() => {
    jesthanagarikPendingPatient()
  }, [])

  const jesthanagarikPendingPatient = () => {
    let url = `${API_URL.pendingPatient}?sewaType=${"5"}&fiscalYear=${DateUtils.getCurrentFiscalYear()}&registerConstant=${JESTHANAGARIK}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setPendingPatientList(jsondata.data);
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="जेष्ठ नागरिक सेवा  रजिष्टर"
          handleModalOpen={openJesthaNagarikaSewaModal}
          getRegisterData={getJesthaNagarikaSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
          showRegisterHideSection
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showJesthaNagarikaSewaModal}
        onModalSubmit={handleSubmit(submitJesthaNagarikaSewaRegister)}
        onModalClose={closeJesthaNagarikaSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
            registerConstant={JESTHANAGARIK}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="नसर्ने रोग भएमा उल्लेख गर्ने"
              size="small"
              variant="outlined"
              name="nonCommuicationDiseaseTest"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nonCommuicationDiseaseTest}
              {...register("nonCommuicationDiseaseTest")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रयोगशाला परीक्षण"
              size="small"
              variant="outlined"
              name="labTest"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.labTest}
              {...register("labTest")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="ज्येष्ठनागरिक सेवा"
              size="small"
              name="jesthaNagarikSewa"
              options={JESTHANAGARIK_SEWA}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.jesthaNagarikSewa}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="institutionName"
              autoComplete="true"
              defaultValue={modalDefaultValues?.institutionName}
              fullWidth
              {...register("institutionName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="institutionAttended"
              autoComplete="true"
              defaultValue={modalDefaultValues?.institutionAttended}
              fullWidth
              {...register("institutionAttended")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      {
        pendingPatientList?.size > 0 &&
        <Box mt={2}>
          <PendingPatientList
            pendingPatientList={pendingPatientList}
            showModal={setJesthaNagarikaSewaModal}
            modalDefaultValues={setModalDefaultValues}
            setValue={setValue}
          />
        </Box>
      }
      <DeleteRegisterRecordConformation
        showDeleteModal={showJesthaNigrikDeleteModal}
        closeDeleteModel={closeJesthaNigrikDeleteModal}
        deleteRecord={deleteJesthaNigrikRow}
      />
      <JesthaNagarikaSewaRegister
        onEditRow={jesthaNagrikSewaEdit}
        tableData={JesthaNagarikaSewaTableData}
        deleteRow={onDeleteJesthaNigrikRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default JesthaNagarikaSewaForm;