import { Box, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal"
import TherapeuticYogaSewaRegister from "../../users/components/register/therapeutic-yoga-sewa-register/TherapeuticYogaSewaRegister"
import NepaliCalender from "../../../components/nepali-calender"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import { useForm } from "react-hook-form"
import { ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, YOG_SEWA_VIVARAN } from "../../../utils/constants"
import { MUL_DARTA_NUMBERS_LIST } from "../../../utils/constants/forms"
import { AppMisc } from "../../../misc/appMisc"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"
import MulDartaaSelect from "../../../components/mul-dartaa-select/MulDartaaSelect"
import { SessionStorage } from "../../../utils/storage/sessionStorage"
import { DateUtils } from "../../../utils/dateUtils"

const TherapeuticYogaSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("उपचारात्मक योग सेवा रजिष्टर")
  const [showTherapeuticYogaModal, setShowTherapeuticYogaModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [getTableData, setGetTableData] = useState(false);
  const [therapeuticTableData, setTherapeuticTableData] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [showtherapeuticSewaDeleteModal, setShowtherapeuticSewaDeleteModal] = useState(false);
  const [therapeuticSewaDeleteId, setTherapeuticSewaDeleteId] = useState(null);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();

  useEffect(() => {
    register("yogSewa", { required: true });
    register("yopVivaran")
  }, [register]);


  useEffect(() => {
    showTherapeuticYogaModal && reset();
  }, [reset, showTherapeuticYogaModal])

  const openTherapeuticYogaModal = () => {
    setShowTherapeuticYogaModal(true);
  }

  const closeTherapeuticYogaModal = () => {
    setShowTherapeuticYogaModal(false)
    setModalDefaultValues(true);
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const submitTherapeuticYogSewa = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.therapeuticSewaRegister, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeTherapeuticYogaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getTherapeuticData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.therapeuticSewaRegister}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setTherapeuticTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const therapeuticSewaEdit = (id) => {
    AHMIS.get(API_URL.therapeuticSewaRegister + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowTherapeuticYogaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closetherapeuticSewaDeleteModal = () => {
    setShowtherapeuticSewaDeleteModal(false);
    setTherapeuticSewaDeleteId(null);
    reset();
  }

  const onDeletetherapeuticSewaRow = (id) => {
    setShowtherapeuticSewaDeleteModal(true);
    setTherapeuticSewaDeleteId(id);
  }

  const deletetherapeuticSewaRow = (data) => {
    AHMIS.delete(API_URL.therapeuticSewaRegister + "/" + therapeuticSewaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowtherapeuticSewaDeleteModal(false);
          setTherapeuticSewaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getTherapeuticData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="उपचारात्मक योग सेवा रजिष्टर"
          handleModalOpen={openTherapeuticYogaModal}
          getRegisterData={getTherapeuticData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showTherapeuticYogaModal}
        onModalSubmit={handleSubmit(submitTherapeuticYogSewa)}
        onModalClose={closeTherapeuticYogaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="व्याधी"
              size="small"
              variant="outlined"
              name="vyadhi"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.vyadhi}
              {...register("vyadhi")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <CustomReactSelect
              label="योगको विवरण"
              name="yopVivaran"
              onChange={handleCustomReactSelectChange}
              options={YOG_SEWA_VIVARAN}
              defaultValue={modalDefaultValues?.yopVivaran}
              creatable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="योगको नाम"
              size="small"
              variant="outlined"
              name="yogName"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.yogName}
              {...register("yogName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="पटक"
              size="small"
              variant="outlined"
              name="type"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.type}
              {...register("type")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="मिति"
              size="small"
              variant="outlined"
              name="dates"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.dates}
              {...register("dates")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="योग सेवा"
              name="yogSewa"
              onChange={handleCustomReactSelectChange}
              options={YOG_SEWA_VIVARAN}
              defaultValue={modalDefaultValues?.yogSewa}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showtherapeuticSewaDeleteModal}
        closeDeleteModel={closetherapeuticSewaDeleteModal}
        deleteRecord={deletetherapeuticSewaRow}
      />
      <TherapeuticYogaSewaRegister
        onEditRow={therapeuticSewaEdit}
        tableData={therapeuticTableData}
        deleteRow={onDeletetherapeuticSewaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default TherapeuticYogaSewaForm;