import { Box, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../../components/register-header/RegisterHeader'
import CustomModal from '../../../../components/modal/CustomModal'
import { AppUtils } from '../../../../utils/appUtils'
import { ERROR, ID, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../../utils/constants'
import AHMIS, { API_URL } from '../../../../api/api'
import AddAlertMessage from '../../../../components/alert/Alert'
import { useForm } from 'react-hook-form'
import NepaliCalender from '../../../../components/nepali-calender'
import { INVALID_BLOOD_PRESSURE_MESSAGE } from '../../../../utils/constants/forms'
import DeleteRegisterRecordConformation from '../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation'
import StanpaiAamaThathaShishuSewaVivaran from '../../../users/components/register/stanpai-aama-tatha-shishu-sewa-register/helper/StanpaiAamaThathaShishuSewaVivaran'

const StanpaiAamaThaShishuVivaranForm = () => {
  const [modalTitle, setModalTitle] = useState("स्तनपायी आमा तथा शिशु सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showStanpaiAamaThaShishuVivaranModal, setShowStanpaiAamaThaShishuVivaranModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [stanpaiAamaThaShishuVivaranSewaBibaranTableData, setStanpaiAamaThaShishuVivaranSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [showStanpaiAamaThaShishuVivaranDeleteModal, setShowStanpaiAamaThaShishuVivaranDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [stanpaiAamaThaShishuVivaranDeleteId, setStanpaiAamaThaShishuVivaranDeleteId] = useState(null);
  const stanpaiAamaThaShishuVivaranRegisterId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    showStanpaiAamaThaShishuVivaranModal && reset();
  }, [reset, showStanpaiAamaThaShishuVivaranModal]);

  const openStanpaiAamaThaShishuVivaranModal = () => {
    setShowStanpaiAamaThaShishuVivaranModal(true);
  };

  const closeStanpaiAamaThaShishuVivaranModal = () => {
    setShowStanpaiAamaThaShishuVivaranModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };


  const submitStanpaiAamaThaShishuVivaranRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    const url = `${API_URL.stanpaiAamaThaShishuVivaranService}?stanpaiAamaThaShishuVivaranServiceRegistrationId=${stanpaiAamaThaShishuVivaranRegisterId}`;
    AHMIS.post(url, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeStanpaiAamaThaShishuVivaranModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getStanpaiAamaThaShishuVivaranSewaBibaranData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.stanpaiAamaThaShishuVivaranService}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&stanpaiAamaThaShishuVivaranServiceRegistrationId=${stanpaiAamaThaShishuVivaranRegisterId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setStanpaiAamaThaShishuVivaranSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const stanpaiAamaThaShishuVivaranSewaBibaranEdit = (id) => {
    AHMIS.get(API_URL.stanpaiAamaThaShishuVivaranService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowStanpaiAamaThaShishuVivaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeStanpaiAamaThaShishuVivaranDeleteModal = () => {
    setShowStanpaiAamaThaShishuVivaranDeleteModal(false);
    setStanpaiAamaThaShishuVivaranDeleteId(null);
  }

  const onDeleteStanpaiAamaThaShishuVivaranRow = (id) => {
    setShowStanpaiAamaThaShishuVivaranDeleteModal(true);
    setStanpaiAamaThaShishuVivaranDeleteId(id);
  }

  const deleteStanpaiAamaThaShishuVivaranSewaBibaranRow = (data) => {
    AHMIS.delete(API_URL.stanpaiAamaThaShishuVivaranService + "/" + stanpaiAamaThaShishuVivaranDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowStanpaiAamaThaShishuVivaranDeleteModal(false);
          setStanpaiAamaThaShishuVivaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getStanpaiAamaThaShishuVivaranSewaBibaranData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };
  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="सेवा विवरण"
          handleModalOpen={openStanpaiAamaThaShishuVivaranModal}
          getRegisterData={getStanpaiAamaThaShishuVivaranSewaBibaranData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showStanpaiAamaThaShishuVivaranModal}
        onModalSubmit={handleSubmit(submitStanpaiAamaThaShishuVivaranRegister)}
        onModalClose={closeStanpaiAamaThaShishuVivaranModal}
        maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="प्रसुति को मिति"
              name="prasutidate"
              defaultDate={modalDefaultValues?.prasutidate}
              onChange={handleDateChange}
            />
            {errors?.date && errors.date?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रसुति भएको स्थान"
              size="small"
              variant="outlined"
              name="prasutiSthan"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.prasutiSthan}
              {...register("prasutiSthan")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रसुतिको प्रकार"
              size="small"
              variant="outlined"
              name="prasutiType"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.prasutiType}
              {...register("prasutiType")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="विवरण"
              size="small"
              variant="outlined"
              name="vivarana"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.vivarana}
              {...register("vivarana")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रक्तचाप (Blood Pressure)"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure", {
                pattern: /^(0|[1-9][0-9]*)\/(0|[1-9][0-9]*)$/,
                required: true
              })}
            />
            {errors.bloodPressure && errors.bloodPressure.type === "pattern" && <span className="error-message">{INVALID_BLOOD_PRESSURE_MESSAGE}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल (KG)"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="मधुमेह"
              size="small"
              variant="outlined"
              name="madhumeha"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.madhumeha}
              {...register("madhumeha")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परिक्षण"
              size="small"
              variant="outlined"
              name="complexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexity}
              {...register("complexity")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="अन्य रोग"
              size="small"
              variant="outlined"
              name="otherDisease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.otherDisease}
              {...register("otherDisease")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showStanpaiAamaThaShishuVivaranDeleteModal}
        closeDeleteModel={closeStanpaiAamaThaShishuVivaranDeleteModal}
        deleteRecord={deleteStanpaiAamaThaShishuVivaranSewaBibaranRow}
      />
      <StanpaiAamaThathaShishuSewaVivaran
        onEditRow={stanpaiAamaThaShishuVivaranSewaBibaranEdit}
        tableData={stanpaiAamaThaShishuVivaranSewaBibaranTableData}
        deleteRow={onDeleteStanpaiAamaThaShishuVivaranRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default StanpaiAamaThaShishuVivaranForm
