
import { Box, Chip, Tooltip } from "@mui/material";
import { AppMisc } from "../../misc/appMisc";
import styles from "./style";
import { Add } from "@mui/icons-material";
import { AppUtils } from "../../utils/appUtils";

const PendingPatientList = ({ pendingPatientList, showModal, modalDefaultValues, setValue }) => {

  const classes = styles();
  const handleApprovedStatus = (id) => {
    showModal(true);
    let patientDetail = { ...pendingPatientList.objectList.find(obj => obj.id === id) };
    patientDetail.palikaName = AppMisc.getMunicipalityName(patientDetail.palikaName);
    setValue("mulDartaaNumber", patientDetail?.mulDartaaNumber);
    delete patientDetail.id;
    delete patientDetail.patientType;
    modalDefaultValues(patientDetail);
  };

  return (
    <Box className={classes.pendingPatientListContainer}>
      <Chip className={classes.totalRemainingCount} label={"आज बाँकी: " + AppUtils.replaceWithNepaliDigit(pendingPatientList?.size) + " जना"} color="secondary" />
      {
        pendingPatientList?.objectList?.map((pendingPatient, index) => (
          <Box key={index}>
            <Tooltip title="सेवाग्राहिलाई रजिस्टरमा दर्ता गर्नको लागि क्लिक गर्नुहोस्।" placement="top">
              <Chip
                label={`${pendingPatient.patientFirstName} ${pendingPatient.patientLastName} (${AppUtils.replaceWithNepaliDigit(pendingPatient.mulDartaaNumber)})`}
                onClick={() => handleApprovedStatus(pendingPatient.id)}
                className={classes.patientChip}
                color="primary"
                variant="outlined"
                icon={<Add style={{ fontSize: '1rem' }} />}
              />
            </Tooltip>
          </Box>
        ))
      }
    </Box>
  );
};

export default PendingPatientList;