import React, { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import { Box, Grid, TextField, Typography } from "@mui/material"
import CustomModal from "../../../components/modal/CustomModal"
import MedicineDistributionRegister from "../../users/components/register/medicine-distribution-register/MedicineDistributionRegister"
import { useForm } from "react-hook-form"
import { ERROR, MEDICINE_NAME, MEDICINEDISTRIBUTION, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants"
import AHMIS, { API_URL } from "../../../api/api"
import AddAlertMessage from "../../../components/alert/Alert"
import MulDartaaSelect from "../../../components/mul-dartaa-select/MulDartaaSelect"
import { SessionStorage } from "../../../utils/storage/sessionStorage"
import { MUL_DARTA_NUMBERS_LIST } from "../../../utils/constants/forms"
import { DateUtils } from "../../../utils/dateUtils"
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect"
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation"

const MedicineDistributionForm = () => {
  const [modalTitle, setModelTitle] = useState("औषधी वितरण ")
  const [showMedicineDistributionFormModal, setshowMedicineDistributionFormModal] = useState(false)
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [getTableData, setGetTableData] = useState(false);
  const [medicineDistributionData, setMedicineDistributionData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [showMedicineDistributionDeleteModal, setShowMedicineDistributionDeleteModal] = useState(false);
  const [medicineDistributionDeleteId, setMedicineDistributionDeleteId] = useState(null);
  const watchFields = watch("medicineDetails");

  const openMedicineDistributionFormModal = () => {
    setshowMedicineDistributionFormModal(true)
  }

  const closeMedicineDistributionFormModal = () => {
    reset()
    setshowMedicineDistributionFormModal(false);
    setMedicineOptions([]);
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  useEffect(() => {
    register("medicineDetails");
  }, [register]);

  useEffect(() => {
    getMedicineOptions();
  }, []);

  const submitMedicineDistribution = (data) => {
    AHMIS.post(API_URL.medicineDistribution, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeMedicineDistributionFormModal()
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getMedicineDistributionData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.medicineDistribution}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setMedicineDistributionData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const medicineDistributionEdit = (id) => {
    AHMIS.get(API_URL.medicineDistribution + "/" + id)
      .then(response => {
        getMedicineOptions();
        setModalDefaultValues(response?.data);
        setshowMedicineDistributionFormModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const getMedicineOptions = () => {
    AHMIS.get(API_URL.medicineList)
      .then((response) => {
        let medicineOption = [];
        medicineOption = response.data?.map((medicineInfo) => ({ label: `${medicineInfo.medicineName} (${medicineInfo.dose} ${medicineInfo.medicineUnit})`, value: medicineInfo.id }))
        setMedicineOptions(medicineOption)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }
  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getMedicineDistributionData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  const closeMedicineDistributionDeleteModal = () => {
    setShowMedicineDistributionDeleteModal(false);
    setMedicineDistributionDeleteId(null);
  }

  const onDeleteMedicineDistributionRow = (id) => {
    setShowMedicineDistributionDeleteModal(true);
    setMedicineDistributionDeleteId(id);
  }

  const deleteMedicineDistributionRow = (data) => {
    AHMIS.delete(API_URL.medicineDistribution + "/" + medicineDistributionDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowMedicineDistributionDeleteModal(false);
          setMedicineDistributionDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="औषधी वितरण रजिष्टर"
          handleModalOpen={openMedicineDistributionFormModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getMedicineDistributionData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showMedicineDistributionFormModal}
        onModalSubmit={handleSubmit(submitMedicineDistribution)}
        onModalClose={closeMedicineDistributionFormModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
            registerConstant={MEDICINEDISTRIBUTION}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">औषधिको नाम</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} >
            <CustomReactSelect
              label="औषधिको नाम"
              name="medicineDetails"
              onChange={handleCustomReactSelectChange}
              options={MEDICINE_NAME}
              defaultValue={modalDefaultValues?.medicineDetails}
              isMulti
              creatable
            />
          </Grid>
          {watchFields && watchFields.length > 0 && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="अल्या"
                  size="small"
                  variant="outlined"
                  name="alya"
                  fullWidth
                  defaultValue={modalDefaultValues?.alya}
                  {...register("alya")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="प्राप्त"
                  size="small"
                  variant="outlined"
                  name="prapta"
                  fullWidth
                  defaultValue={modalDefaultValues?.prapta}
                  {...register("prapta")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="खर्च"
                  size="small"
                  variant="outlined"
                  name="kharchha"
                  fullWidth
                  defaultValue={modalDefaultValues?.kharchha}
                  {...register("kharchha")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="बाँकी"
                  size="small"
                  variant="outlined"
                  name="baki"
                  fullWidth
                  defaultValue={modalDefaultValues?.baki}
                  {...register("baki")}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showMedicineDistributionDeleteModal}
        closeDeleteModel={closeMedicineDistributionDeleteModal}
        deleteRecord={deleteMedicineDistributionRow}
      />
      <MedicineDistributionRegister
        onEditRow={medicineDistributionEdit}
        onDeleteRow={onDeleteMedicineDistributionRow}
        tableData={medicineDistributionData}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default MedicineDistributionForm;