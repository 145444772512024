import { Delete, Edit } from "@mui/icons-material";
import { Box, Card, CardContent, Divider, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { GENDER_OPTIONS, NO_RECORDS_FOUND } from "../../../../../../utils/constants";
import { AppUtils } from "../../../../../../utils/appUtils";
import { AppMisc } from "../../../../../../misc/appMisc";
import { getDataFromServer } from "../../../../../../utils/apiUtils";
import { API_URL } from "../../../../../../api/api";


const StanpaiAamaThathaShishuSewaVivaran = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();
  const [cardData, setCardData] = useState([]);
  const id = AppUtils.getUrlParam("id");

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  const getCardData = async () => {
    let url = API_URL.stanpaiAama + "/" + id
    let response = await getDataFromServer(url)
    if (response) {
      setCardData(response)
    }
  }

  useEffect(() => {
    getCardData()
  }, [])

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">सेवाग्राहीको सामान्य विवरण</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              दर्ता मिति:{AppMisc.getDateLabelFromMillisecond(cardData?.dartaaMiti)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              नाम:{cardData?.patientFirstName}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              थर:{cardData?.patientLastName}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              उमेर:{cardData?.age && AppUtils.replaceWithNepaliDigit(cardData.age)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              लिङ्ग:{cardData?.gender && AppMisc.findLabelFromValue(GENDER_OPTIONS, cardData.gender)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              जिल्ला:{cardData?.district && AppMisc.getDistrictName(cardData.district)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              नगर/गाउँपालिका:{cardData?.palikaName}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              वडा नं:{cardData?.wardNumber && AppUtils.replaceWithNepaliDigit(cardData.wardNumber)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              टोल:{cardData?.gaunOrTole}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              सम्पर्क नं.:{cardData?.phoneNumber && AppUtils.replaceWithNepaliDigit(cardData?.phoneNumber)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>क्र.सं.</TableCell>
                <TableCell rowSpan={2}>प्रसुति को मिति</TableCell>
                <TableCell rowSpan={2}>प्रसुति भएको स्थान</TableCell>
                <TableCell rowSpan={2}>प्रसुतिको प्रकार</TableCell>
                <TableCell colSpan={6}>स्वास्थ्य अवस्था</TableCell>
                <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
                <TableCell rowSpan={2} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>रक्तचाप</TableCell>
                <TableCell>तौल </TableCell>
                <TableCell>मधुमेह</TableCell>
                <TableCell>प्रकृति</TableCell>
                <TableCell>परिक्षण</TableCell>
                <TableCell> अन्य रोग</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData?.length > 0 ? (
                mainRegisterData?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.prasutidate)}</TableCell>
                    <TableCell>{row?.prasutiSthan || "-"}</TableCell>
                    <TableCell>{row?.prasutiType || "-"}</TableCell>
                    <TableCell>{row?.vivarana || "-"}</TableCell>
                    <TableCell>{row.bloodPressure || "-"}</TableCell>
                    <TableCell>{row.weight || "-"}</TableCell>
                    <TableCell>{row.madhumeha || "-"}</TableCell>
                    <TableCell>{row.nature || "-"}</TableCell>
                    <TableCell>{row.complexity || "-"}</TableCell>
                    <TableCell>{row.otherDisease || "-"}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color="secondary" onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="41" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default StanpaiAamaThathaShishuSewaVivaran

