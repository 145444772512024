import { useEffect, useState } from "react";
import { AppMisc } from "../../../misc/appMisc";
import { useForm } from "react-hook-form";
import AHMIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { ERROR, GHAR_BHETA_SEWA, NAGARIKAROGYA, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import { Box, Grid, TextField, Typography } from "@mui/material";
import RegisterHeader from "../../../components/register-header/RegisterHeader";
import CustomModal from "../../../components/modal/CustomModal";
import MulDartaaSelect from "../../../components/mul-dartaa-select/MulDartaaSelect";
import PendingPatientList from "../../../components/pending-patient-list/PendingPatientList";
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import NagrikAAarogyaSewaRegister from "../../users/components/register/nagrik-aarogya-sewa-register/NagrikAAarogyaSewaRegister";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import { MUL_DARTA_NUMBERS_LIST } from "../../../utils/constants/forms";
import { DateUtils } from "../../../utils/dateUtils";
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect";

const NagrikAarogyaSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("नागरिक आरोग्य सेवा रजिष्टर थप्नुहोस्।");
  const [showNagrikAarogyaSewaModal, setShowNagrikAarogyaSewaModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState();
  const [nagrikAarogyaSewaDataTable, setNagrikAarogyaSewaDataTable] = useState([]);
  const [showNagrikAarogyaSewaDeleteModal, setShowNagrikAarogyaSewaDeleteModal] = useState(false);
  const [nagrikAarogyaSewaDeleteId, setNagrikAarogyaSewaDeleteId] = useState(null);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [pendingPatientList, setPendingPatientList] = useState([])

  useEffect(() => {
    register("gharBhetaSewa");
  }, [register]);

  useEffect(() => {
    showNagrikAarogyaSewaModal && reset();
  }, [reset, showNagrikAarogyaSewaModal])

  const openNagrikAarogyaSewaModal = () => {
    setShowNagrikAarogyaSewaModal(true);
  }

  const closeNagrikAarogyaSewaModal = () => {
    reset();
    setShowNagrikAarogyaSewaModal(false);
    setModalDefaultValues()
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }


  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const submitNagrikAarogyaSewaForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.nagrikAArogya, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeNagrikAarogyaSewaModal()
          setGetTableData(true)
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const getNagrikAarogyaData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.nagrikAArogya}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setNagrikAarogyaSewaDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  useEffect(() => {
    nagrikAarogyaSewaPendingPatient()
  }, [])

  const nagrikAarogyaSewaPendingPatient = () => {
    let url = `${API_URL.nagrikAArogya}`;
    AHMIS.get(url).then((response) => {
      let jsondata = response.data;
      setPendingPatientList(jsondata.data)
    })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const nagrikAarogyaSewaEdit = (id) => {
    AHMIS.get(API_URL.nagrikAArogya + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data?.data);
        setShowNagrikAarogyaSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const closeMulDartaaDeleteModal = () => {
    setShowNagrikAarogyaSewaDeleteModal(false);
    setNagrikAarogyaSewaDeleteId(null);
  }

  const onDeleteNagrikAarogyaSewaRow = (id) => {
    setShowNagrikAarogyaSewaDeleteModal(true);
    setNagrikAarogyaSewaDeleteId(id);
  }

  const deleteNagrikAarogyaRow = (data) => {
    AHMIS.delete(API_URL.nagrikAArogya + "/" + nagrikAarogyaSewaDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowNagrikAarogyaSewaDeleteModal(false);
          setNagrikAarogyaSewaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getNagrikAarogyaData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="नागरिक आरोग्य सेवा रजिष्टर"
          handleModalOpen={openNagrikAarogyaSewaModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getNagrikAarogyaData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showNagrikAarogyaSewaModal}
        onModalSubmit={handleSubmit(submitNagrikAarogyaSewaForm)}
        onModalClose={closeNagrikAarogyaSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
            registerConstant={NAGARIKAROGYA}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} >
            <CustomReactSelect
              label="घरभेट सेवा"
              name="gharBhetGhatSewa"
              onChange={handleCustomReactSelectChange}
              options={GHAR_BHETA_SEWA}
              defaultValue={modalDefaultValues?.gharBhetGhatSewa}
              creatable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="जीवनशैली परामर्श जना"
              size="small"
              variant="outlined"
              type="number"
              name="givanSailiParamarsha"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.givanSailiParamarsha}
              {...register("givanSailiParamarsha")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="अभियान मूलक कार्यक्रम पटक"
              size="small"
              variant="outlined"
              name="abhiyanMulakProgramPatak"
              autoComplete="true"
              type="number"
              fullWidth
              defaultValue={modalDefaultValues?.abhiyanMulakProgramPatak}
              {...register("abhiyanMulakProgramPatak")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="अन्य सेवा"
              size="small"
              variant="outlined"
              name="otherSewa"
              autoComplete="true"
              defaultValue={modalDefaultValues?.otherSewa}
              fullWidth
              {...register("otherSewa")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      {
        pendingPatientList?.size > 0 &&
        <Box mt={2}>
          <PendingPatientList
            pendingPatientList={pendingPatientList}
            showModal={setShowNagrikAarogyaSewaModal}
            modalDefaultValues={setModalDefaultValues}
            setValue={setValue}
          />
        </Box>
      }
      <DeleteRegisterRecordConformation
        showDeleteModal={showNagrikAarogyaSewaDeleteModal}
        closeDeleteModel={closeMulDartaaDeleteModal}
        deleteRecord={deleteNagrikAarogyaRow}
      />
      <Box mt={2}>
        <NagrikAAarogyaSewaRegister
          onEditRow={nagrikAarogyaSewaEdit}
          tableData={nagrikAarogyaSewaDataTable}
          deleteRow={onDeleteNagrikAarogyaSewaRow}
          handleChangePage={handleChangePage}
          page={page}
          totalSize={totalSize}
          getTableData={(value) => setGetTableData(value)}
        />
      </Box>
    </>
  )
}

export default NagrikAarogyaSewaForm;