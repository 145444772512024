import { CssBaseline } from "@mui/material";
import { ReactNotifications } from 'react-notifications-component';
import { Route, Routes } from "react-router-dom";
import "./assets/scss/app.scss";
import MulDartaa from "./pages/users/mul-dartaa/MulDartaa";
import LoginForm from "./pages/public/login/Login";
import RouteWrapper from "./routes/Route";
import OutPatientForm from "./pages/forms/out-patient-form/OutPatientForm";
import MedicineDistributionForm from "./pages/forms/medicine-distribution-form/MedicineDistributionForm";
import KsharsutraSewaForm from "./pages/forms/ksharsutra-sewa-form/KsharsutraSewaForm";
import AdminDashboard from "./pages/admin/dashboard/Dashboard";
import Layout from "./components/layout/Layout";
import PanchaKarmaSewaForm from "./pages/forms/panchakarma-sewa-form/PanchaKarmaSewaForm";
import TherapeuticYogaSewaForm from "./pages/forms/therapeutic-yoga-sewa-register/TherapeuticYogaSewaForm";
import OrcForms from "./pages/forms/ORC/OrcForms";
import UserProfile from "./pages/users/profile/Profile";
import UserSetting from "./pages/users/setting/setting";
import GlobalPageNotFound from "./pages/public/not-found/GlobalPageNotFound";
import ResetPassword from "./pages/public/reset-password/ResetPassword";
import NotAuthorized from "./pages/public/not-authorized/NotAuthorized";
import ForgetPassword from "./pages/public/forget-password/ForgetPassword";
import GaugharClinicJesthaNagarikSewaForm from "./pages/forms/gaughar-sahari-clinic-jestha-nagarik-sewa-form/GaugharClinicJesthaNagarikSewaForm";
import GaugharSahariClinicNasarneRogaVyavasthapanSewaForm from "./pages/forms/gaughar-sahari-clinic-nasarne-roga-vyavasthapan-sewa--form/GaugharSahariClinicNasarneRogaVyavasthapanSewaForm";
import GaugharSahariClinicParamarshaSewaForm from "./pages/forms/gaughar-sahari-clinic-paramarsha-sewa-form/GaugharSahariClinicParamarshaSewaForm";
import PanchaKarmaPardanKarmaSewaReport from "./pages/reports/pancha-karma-pardan-karma-sewa-report/PanchaKarmaPardanKarmaSewaReport";
import JesthaNagrikSewaReport from "./pages/reports/jestha-nagrik-sewa-report/JesthaNagrikSewaReport";
import KsharasutraSewaReport from "./pages/reports/ksharasutra-sewa-report/KsharasutraSewaReport";
import TherapeuticYogaServiceReport from "./pages/reports/therapeutic-yoga-service-report/TherapeuticYogaServiceReport";
import BreastfeedingMaternalAndChildServicesReport from "./pages/reports/breastfeeding-maternal-and-child-services-report/BreastfeedingMaternalAndChildServicesReport";
import VillageAyurvedaClinicReport from "./pages/reports/village-ayurveda-clinic-report/VillageAyurvedaClinicReport";
import MonthlyMedicineDispensingReport from "./pages/reports/monthly-medicine-dispensing-report/MonthlyMedicineDispensingReport";
import OutPatientReport from "./pages/reports/out-patient-report/OutPatientReport";
import InpatientMorbidity from "./pages/reports/inpatient-morbidity/InpatientMorbidity";
import HospitalReports from "./pages/reports/hospital-reports/HospitalReports";
import MonthlyProgressReport from "./pages/reports/ monthly-progress-report/ MonthlyProgressReport";
import AdmissionForm from "./pages/forms/admission-form/AdmissionForm";
import DischargeForm from "./pages/forms/discharge-form/DischargeForm";
import JesthaNagarikaSewaForm from "./pages/forms/jestha-nagarika-sewa-form/JesthaNagarikaSewaForm";
import StanpaiAamatathaShishuSewaForm from "./pages/forms/stanpai-aama-tatha-shishu-sewa-form/StanpaiAamatathaShishuSewaForm";
import AddMedicineForm from "./pages/forms/add-medicine-form/AddMedicineForm";
import DhisTwoSetup from "./pages/users/components/register/system-setting/dhis-two-setup/DhisTwoSetup";
import StaffDetails from "./pages/users/components/register/system-setting/staff-details/StaffDetails";
import DeletedRecords from "./pages/users/components/register/deleted-records/DeletedRecords";
import EmergencyServiceForm from "./pages/forms/emergency-service-form/EmergencyServiceForm";
import MunicipalityHome from "./pages/admin/municipality-home/MunicipalityHome";
import HealthClientHome from "./pages/admin/components/health-client-home/HealthClientHome";
import Dashboard from "./components/dashboard/Dashboard";
import PanchaKarmaPurbaKarmaSewaReport from "./pages/reports/pancha-karma-purba-karma-sewa-report/PanchaKarmaPurbaSewaReport";
import PanchaKarmaSewaBibaranForm from "./pages/forms/panchakarma-sewa-form/helper/PanchaKarmaSewaBibaranForm";
import BittayaBibaranForm from "./pages/forms/bittaya-bibaran/BittayaBibaranForm";
import KarmachariBibaranForm from "./pages/forms/Karmachari-Bibaran-form/KarmachariBibaranForm";
import ShalyaChikitsaForm from "./pages/forms/shalya-chikitsa-form/ShalyaChikitsaForm";
import ShalyaChikitsaSewaBibaranForm from "./pages/forms/shalya-chikitsa-form/helper/ShalyaChikitsaSewaBibaranForm";
import NcdForm from "./pages/forms/Ncd-form/NcdForm";
import AcupunctureForm from "./pages/forms/acupuncture-form/AcupunctureForm";
import AcupunctureSewaBibaranForm from "./pages/forms/acupuncture-form/helper/AcupunctureSewaBibaranForm";
import PhysiotherapyForm from "./pages/forms/physiotherapy-form/PhysiotherapyForm";
import PhysiotherapySewaBibaranForm from "./pages/forms/physiotherapy-form/helper/PhysiotherapySewaBibaranForm";
import YogSewaForm from "./pages/forms/yog-sewa-form/YogSewaForm";
import YogSewaBibaranForm from "./pages/forms/yog-sewa-form/helper/YogSewaBibaranForm";
import JesthaNagrikHealthSewaForm from "./pages/forms/jestha-nagrik-health-sewa-form/JesthaNagrikHealthSewaForm";
import SalyaChikitsaReport from "./pages/reports/salya-chikitsa-report/SalyaChikitsaReport";
import YogSewaReport from "./pages/reports/yog-sewa-report/YogSewaReport";
import PhysioTheraptReport from "./pages/reports/physio-therapy-report/PhysioTherapyReport";
import PhysioTherapyReport from "./pages/reports/physio-therapy-report/PhysioTherapyReport";
import AccpuntureReport from "./pages/reports/accpunture-report/AccpuntureReport";
import AnatmakTathaAbhiyanamulakaYogForm from "./pages/forms/prabardhanatmak-tatha-abhiyanamulaka-yog-form/AnatmakTathaAbhiyanamulakaYogForm";
import HomeoPathyForm from "./pages/forms/homeo-pathy-sewa-form/HomeoPathyForm";
import NagrikAarogyaSewaForm from "./pages/forms/nagrik-aarogya-sewa-form/NagrikAarogyaSewaForm";
import StanpaiAamaThathaShishuSewaVivaran from "./pages/users/components/register/stanpai-aama-tatha-shishu-sewa-register/helper/StanpaiAamaThathaShishuSewaVivaran";
import StanpaiAamaThaShishuVivaranForm from "./pages/forms/stanpai-aama-tatha-shishu-sewa-form/helper/StanpaiAamaThaShishuVivaranForm";
import TherapeuticYogSewaVivaranForm from "./pages/forms/therapeutic-yoga-sewa-register/helper/TherapeuticYogSewaVivaranForm";

function App() {
  return (
    <>
      <ReactNotifications />
      <CssBaseline />
      <Layout>
        <Routes>
          <Route path="/" element={<RouteWrapper path="/" component={LoginForm} />} />
          <Route path="/page-not-found" element={<RouteWrapper path="/page-not-found" component={GlobalPageNotFound} isWrongLink />} />
          <Route path="/user-not-authorized" element={<RouteWrapper path="/user-not-authorized" component={NotAuthorized} />} />
          <Route path="/forget-password" element={<RouteWrapper path="/forget-password" component={ForgetPassword} />} />
          <Route exact path="/reset" element={<RouteWrapper path="/forget-password" component={ResetPassword} />} />
          {/* admin  */}
          <Route path="/admin/dashboard" element={<RouteWrapper path="/admin/dashboard" component={AdminDashboard} isPrivate />} />
          <Route path="/admin/setting" element={<RouteWrapper path="/admin/setting" component={UserSetting} isPrivate />} />
          <Route path="/admin/profile" element={<RouteWrapper path="/admin/profile" component={UserProfile} isPrivate />} />
          <Route path="/admin/municipality-home" element={<RouteWrapper path="/admin/municipality-home" component={MunicipalityHome} isPrivate />} />
          <Route path="/admin/health-client-home" element={<RouteWrapper path="/admin/health-client-home" component={HealthClientHome} isPrivate />} />

          {/* user  */}
          <Route path="/user/dashboard" element={<RouteWrapper path="/user/dashboard" component={Dashboard} isPrivate />} />
          <Route path="/user/profile" element={<RouteWrapper path="/user/profile" component={UserProfile} isPrivate />} />
          <Route path="/user/setting" element={<RouteWrapper path="/user/setting" component={UserSetting} isPrivate />} />
          <Route path="/user/mul-dartaa" element={<RouteWrapper path="/user/mul-dartaa" component={MulDartaa} isPrivate />} />
          <Route path="/user/out-patient" element={<RouteWrapper path="/user/out-patient" component={OutPatientForm} isPrivate />} />
          <Route path="/user/ksharsutra-sewa" element={<RouteWrapper path="user/ksharsutra-sewa" component={KsharsutraSewaForm} isPrivate />} />
          <Route path="/user/medicine-distribution" element={<RouteWrapper path="/user/medicine-distribution" component={MedicineDistributionForm} isPrivate />} />
          <Route path="/user/panchakarma-sewa" element={<RouteWrapper path="/user/panchakarma-sewa" component={PanchaKarmaSewaForm} isPrivate />} />
          <Route path="/user/therapeutic-yoga-sewa" element={<RouteWrapper path="/user/therapeutic-yoga-sewa" component={TherapeuticYogaSewaForm} isPrivate />} />
          <Route path="/user/orc-sewa" element={<RouteWrapper path="/user/orc-sewa" component={OrcForms} isPrivate />} />
          <Route path="/user/gaughar-clinic-jestha-nagarik" element={<RouteWrapper path="/user/gau-ghar-clinic-jestha-nagarik" component={GaugharClinicJesthaNagarikSewaForm} isPrivate />} />
          <Route path="/user/gausahar-clinic-nasarne-rog-vyavasthapan" element={<RouteWrapper path="/user/gausahar-clinic-nasarne-rog-vyavasthapan" component={GaugharSahariClinicNasarneRogaVyavasthapanSewaForm} isPrivate />} />
          <Route path="/user/gausahar-sahari-clinic-paramarsha-sewa" element={<RouteWrapper path="/user/gausahar-sahari-clinic-paramarsha-sewa" component={GaugharSahariClinicParamarshaSewaForm} isPrivate />} />
          <Route path="/user/admission" element={<RouteWrapper path="/user/admission" component={AdmissionForm} isPrivate />} />
          <Route path="/user/discharge" element={<RouteWrapper path="/user/discharge" component={DischargeForm} isPrivate />} />
          <Route path="/user/emergency-service" element={<RouteWrapper path="/user/emergency-service" component={EmergencyServiceForm} isPrivate />} />
          <Route path="/user/jestha-nagarika" element={<RouteWrapper path="/user/jestha-nagarika" component={JesthaNagarikaSewaForm} isPrivate />} />
          <Route path="/user/stanpai-sama-tatha-shishu" element={<RouteWrapper path="/user/stanpai-sama-tatha-shishu" component={StanpaiAamatathaShishuSewaForm} isPrivate />} />
          <Route path="/user/add-medicine" element={<RouteWrapper path="/user/add-medicine" component={AddMedicineForm} isPrivate />} />
          <Route path="/user/dhis-two-setup" element={<RouteWrapper path="/user/dhis-two-setup" component={DhisTwoSetup} isPrivate />} />
          <Route path="/user/staff-details" element={<RouteWrapper path="/user/staff-details" component={StaffDetails} isPrivate />} />
          <Route path="/user/deleted-records" element={<RouteWrapper path="/user/deleted-records" component={DeletedRecords} isPrivate />} />
          <Route path="/user/pancharkarma-sewa-bibaran" element={<RouteWrapper path="/user/pancharkarma-sewa-bibaran" component={PanchaKarmaSewaBibaranForm} isPrivate />} />
          <Route path="/user/bittayabivaran" element={<RouteWrapper path="/user/bittayabivaran" component={BittayaBibaranForm} isPrivate />} />
          <Route path="/user/karmacharibivaran" element={<RouteWrapper path="/user/karmacharibivaran" component={KarmachariBibaranForm} isPrivate />} />
          <Route path="/user/shalyachikitsa" element={<RouteWrapper path="/user/shalyachikitsa" component={ShalyaChikitsaForm} isPrivate />} />
          <Route path="/user/shalyachikitsa-sewa-bibaran" element={<RouteWrapper path="/user/shalyachikitsa-sewa-bibaran" component={ShalyaChikitsaSewaBibaranForm} isPrivate />} />
          <Route path="/user/ncd" element={<RouteWrapper path="/user/ncd" component={NcdForm} isPrivate />} />
          <Route path="/user/acupuncture" element={<RouteWrapper path="/user/acupuncture" component={AcupunctureForm} isPrivate />} />
          <Route path="/user/acupuncture-sewa-bibaran" element={<RouteWrapper path="/user/acupuncture-sewa-bibaran" component={AcupunctureSewaBibaranForm} isPrivate />} />
          <Route path="/user/physiotherapy" element={<RouteWrapper path="/user/physiotherapy" component={PhysiotherapyForm} isPrivate />} />
          <Route path="/user/physiotherapy-sewa-bibaran" element={<RouteWrapper path="/user/physiotherapy-sewa-bibaran" component={PhysiotherapySewaBibaranForm} isPrivate />} />
          <Route path="/user/yog-sewa" element={<RouteWrapper path="/user/yog-sewa" component={YogSewaForm} isPrivate />} />
          <Route path="/user/yog-sewa-bibaran" element={<RouteWrapper path="/user/yog-sewa-bibaran" component={YogSewaBibaranForm} isPrivate />} />
          <Route path="/user/jestha-nagrik-heath-sewa" element={<RouteWrapper path="/user/jestha-nagrik-heath-sewa" component={JesthaNagrikHealthSewaForm} isPrivate />} />
          <Route path="/user/anatmak-tatha-abhiyanamulaka-yog" element={<RouteWrapper path="/user/anatmak-tatha-abhiyanamulaka-yog" component={AnatmakTathaAbhiyanamulakaYogForm} isPrivate />} />
          <Route path="/user/homeo-pathy" element={<RouteWrapper path="/user/homeo-pathy" component={HomeoPathyForm} isPrivate />} />
          <Route path="/user/nagrik-aarogya-sewa" element={<RouteWrapper path="/user/nagrik-aarogya-sewa" component={NagrikAarogyaSewaForm} isPrivate />} />
          <Route path="/user/stanpai-ama-thatha-shishu-vivaran" element={<RouteWrapper path="/user/stanpai-ama-thatha-shishu-vivaran" component={StanpaiAamaThaShishuVivaranForm} isPrivate />} />
          <Route path="/user/therapeutic-yog-sewa-vivaran" element={<RouteWrapper path="/user/therapeutic-yog-sewa-vivaran" component={TherapeuticYogSewaVivaranForm} isPrivate />} />

          {/* report  */}
          <Route path="/user/pancha-karma-purba-karma-sewa-report" element={<RouteWrapper path="/user/pancha-karma-purba-karma-sewa-report" component={PanchaKarmaPurbaKarmaSewaReport} isPrivate />} />
          <Route path="/user/pancha-karma-pardan-karma-report" element={<RouteWrapper path="/user/pancha-karma-pardan-karma-report" component={PanchaKarmaPardanKarmaSewaReport} isPrivate />} />
          <Route path="/user/jestha-nagrik-report" element={<RouteWrapper path="/user/jestha-nagrik-report" component={JesthaNagrikSewaReport} isPrivate />} />
          <Route path="/user/ksharasutra-sewa-report" element={<RouteWrapper path="/user/ksharasutra-sewa-report" component={KsharasutraSewaReport} isPrivate />} />
          <Route path="/user/therapeutic-yoga-service-report" element={<RouteWrapper path="/user/therapeutic-yoga-service-report" component={TherapeuticYogaServiceReport} isPrivate />} />
          <Route path="/user/breastfeeding-maternal-and-child-services-report" element={<RouteWrapper path="/user/breastfeeding-maternal-and-child-services-report" component={BreastfeedingMaternalAndChildServicesReport} isPrivate />} />
          <Route path="/user/village-ayurveda-clinic-report" element={<RouteWrapper path="/user/village-ayurveda-clinic-report" component={VillageAyurvedaClinicReport} isPrivate />} />
          <Route path="/user/monthly-medicine-dispensing-report" element={<RouteWrapper path="/user/monthly-medicine-dispensing-report" component={MonthlyMedicineDispensingReport} isPrivate />} />
          <Route path="/user/out-patient-report" element={<RouteWrapper path="/user/out-patient-report" component={OutPatientReport} isPrivate />} />
          <Route path="/user/in-patient-morbidity" element={<RouteWrapper path="/user/in-patient-morbidity" component={InpatientMorbidity} isPrivate />} />
          <Route path="/user/hospital-reports" element={<RouteWrapper path="/user/hospital-reports" component={HospitalReports} isPrivate />} />
          <Route path="/user/monthly-progress-report" element={<RouteWrapper path="/user/monthly-progress-report" component={MonthlyProgressReport} isPrivate />} />
          <Route path="/user/salyachikitsa-report" element={<RouteWrapper path="/user/salyachikitsa-report" component={SalyaChikitsaReport} isPrivate />} />
          <Route path="/user/yog-sewa-report" element={<RouteWrapper path="/user/yog-sewa-report" component={YogSewaReport} isPrivate />} />
          <Route path="/user/physio-therapy-report" element={<RouteWrapper path="/user/physio-therapy-report" component={PhysioTherapyReport} isPrivate />} />
          <Route path="/user/accpunture-therapy-report" element={<RouteWrapper path="/user/accpunture-report" component={AccpuntureReport} isPrivate />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;