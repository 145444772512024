import { useEffect, useState } from "react";
import { AppUtils } from "../../../../utils/appUtils";
import { ERROR, ID, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, YOG_SEWA_VIVARAN } from "../../../../utils/constants";
import AHMIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { Box, Grid, TextField } from "@mui/material";
import RegisterHeader from "../../../../components/register-header/RegisterHeader";
import CustomModal from "../../../../components/modal/CustomModal";
import NepaliCalender from "../../../../components/nepali-calender";
import DeleteRegisterRecordConformation from "../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import TherapeuticYogSewaVivaran from "../../../users/components/register/therapeutic-yoga-sewa-register/helper/TherapeuticYogSewaVivaran";
import { useForm } from "react-hook-form";
import CustomReactSelect from "../../../../components/custom-react-select/CustomReactSelect";

const TherapeuticYogSewaVivaranForm = () => {
  const [modalTitle, setModalTitle] = useState("उपचारात्मक सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showTherapeuticYogSewaVivaranModal, setShowTherapeuticYogSewaVivaranModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [therapeuticYogSewaVivaranSewaBibaranTableData, setTherapeuticYogSewaVivaranSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [showTherapeuticYogSewaVivaranDeleteModal, setShowTherapeuticYogSewaVivaranDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [therapeuticYogSewaVivaranDeleteId, setTherapeuticYogSewaVivaranDeleteId] = useState(null);
  const therapeuticYogSewaId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    showTherapeuticYogSewaVivaranModal && reset();
  }, [reset, showTherapeuticYogSewaVivaranModal]);

  const openTherapeuticYogSewaVivaranModal = () => {
    setShowTherapeuticYogSewaVivaranModal(true);
  };

  const closeTherapeuticYogSewaVivaranModal = () => {
    setShowTherapeuticYogSewaVivaranModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitTherapeuticYogSewaVivaran = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    const url = `${API_URL.therapeuticYogService}?therapeuticYogRegistrationId=${therapeuticYogSewaId}`;
    AHMIS.post(url, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeTherapeuticYogSewaVivaranModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  }

  const getTherapeuticYogSewaVivaranSewaBibaranData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.therapeuticYogService}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&therapeuticYogRegistrationId=${therapeuticYogSewaId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setTherapeuticYogSewaVivaranSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const therapeuticYogSewaVivaranSewaBibaranEdit = (id) => {
    AHMIS.get(API_URL.therapeuticYogService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowTherapeuticYogSewaVivaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeTherapeuticYogSewaVivaranDeleteModal = () => {
    setShowTherapeuticYogSewaVivaranDeleteModal(false);
    setTherapeuticYogSewaVivaranDeleteId(null);
  }

  const onDeleteTherapeuticYogSewaVivaranRow = (id) => {
    setShowTherapeuticYogSewaVivaranDeleteModal(true);
    setTherapeuticYogSewaVivaranDeleteId(id);
  }

  const deleteTherapeuticYogSewaVivaranSewaBibaranRow = (data) => {
    AHMIS.delete(API_URL.therapeuticYogService + "/" + therapeuticYogSewaVivaranDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowTherapeuticYogSewaVivaranDeleteModal(false);
          setTherapeuticYogSewaVivaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getTherapeuticYogSewaVivaranSewaBibaranData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  useEffect(() => {
    if (showTherapeuticYogSewaVivaranModal) {
      gettherapeuticYogServiceCount()
    }
  }, [showTherapeuticYogSewaVivaranModal])

  const gettherapeuticYogServiceCount = () => {
    let url = `${API_URL.serviceTakenCount}?register=${"therapeuticyogservice"}`;
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setValue("service", jsondata.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="सेवा विवरण"
          handleModalOpen={openTherapeuticYogSewaVivaranModal}
          getRegisterData={getTherapeuticYogSewaVivaranSewaBibaranData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showTherapeuticYogSewaVivaranModal}
        onModalSubmit={handleSubmit(submitTherapeuticYogSewaVivaran)}
        onModalClose={closeTherapeuticYogSewaVivaranModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="मिति"
              name="dartaaMiti"
              defaultDate={modalDefaultValues?.dartaaMiti}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवा पटक"
              size="small"
              variant="outlined"
              name="service"
              type="number"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.service}
              {...register("service")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="योग सेवा"
              name="yogSewa"
              onChange={handleCustomReactSelectChange}
              options={YOG_SEWA_VIVARAN}
              defaultValue={modalDefaultValues?.yogSewa}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण सल्लाह"
              size="small"
              variant="outlined"
              name="testing"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("testing")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexity}
              {...register("complexity")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showTherapeuticYogSewaVivaranDeleteModal}
        closeDeleteModel={closeTherapeuticYogSewaVivaranDeleteModal}
        deleteRecord={deleteTherapeuticYogSewaVivaranSewaBibaranRow}
      />
      <TherapeuticYogSewaVivaran
        onEditRow={therapeuticYogSewaVivaranSewaBibaranEdit}
        tableData={therapeuticYogSewaVivaranSewaBibaranTableData}
        deleteRow={onDeleteTherapeuticYogSewaVivaranRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  );
};

export default TherapeuticYogSewaVivaranForm;

