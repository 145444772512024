import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

const NcdReport = () => {
  return (
    <TableContainer classes={{ root: "fixed-head-table-container" }}  >
      <Typography variant="body1" className="report-heading">नसर्ने रोगको  प्रतिबेदन </Typography>
      <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="NCD-Sewa-Report">
        <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
          <TableRow>
            <TableCell rowSpan={2}>सेवाको प्रकार अनुसार सेवा लिएको विवरण</TableCell>
            <TableCell colSpan={3}>मनोसामाजिक सेवा</TableCell>
            <TableCell colSpan={3}>परामर्श सेवा</TableCell>
            <TableCell colSpan={3}>औषधी</TableCell>
            <TableCell colSpan={3}>अन्य</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>महिला</TableCell>
            <TableCell>पुरूष</TableCell>
            <TableCell>अन्य</TableCell>
            <TableCell>महिला</TableCell>
            <TableCell>पुरूष</TableCell>
            <TableCell>अन्य</TableCell>
            <TableCell>महिला</TableCell>
            <TableCell>पुरूष</TableCell>
            <TableCell>अन्य</TableCell>
            <TableCell>महिला</TableCell>
            <TableCell>पुरूष</TableCell>
            <TableCell>अन्य</TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>नसर्ने रोगी</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>मानसिक रोगी</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>चोट लागेको</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>असक्षमता भएको</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>जम्मा</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NcdReport
