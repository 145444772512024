import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import { useForm } from 'react-hook-form';
import AHMIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, PHYSIOTHERAPY, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import CustomModal from '../../../components/modal/CustomModal';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import { DateUtils } from '../../../utils/dateUtils';
import { AppMisc } from '../../../misc/appMisc';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import PhysiotherapyRegister from '../../users/components/register/physiotherapy-register/PhysiotherapyRegister';
import PendingPatientList from '../../../components/pending-patient-list/PendingPatientList';

const PhysiotherapyForm = () => {

  const [modalTitle, setModalTitle] = useState("फिजियोथेरापी सेवा नयाँ रेकर्ड थप्नुहोस्।");
  const [showPhysioTherapyModal, setShowPhysioTherapyModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [physiotherapyDataTable, setPhysiotherapyDataTable] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [page, setPage] = useState(0);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [showPhysioTherapyDeleteModal, setShowPhysioTherapyDeleteModal] = useState(false);
  const [physioTherapyDeleteId, setPhysioTherapyDeleteId] = useState(null);
  const [pendingPatientList, setPendingPatientList] = useState([])

  useEffect(() => {
    showPhysioTherapyModal && reset();
  }, [reset, showPhysioTherapyModal])

  const openPhysioTherapyModal = () => {
    setShowPhysioTherapyModal(true);
  }

  const closePhysioTherapyModal = () => {
    reset();
    setShowPhysioTherapyModal(false);
    setModalDefaultValues()
  }

  const getPhysioTherapyData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.physiotherapySewaRegister}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setPhysiotherapyDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const submitPhysioTherapyRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.physiotherapySewaRegister, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closePhysioTherapyModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getPhysioTherapyData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const physioTherpyEdit = (id) => {
    AHMIS.get(API_URL.physiotherapySewaRegister + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowPhysioTherapyModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const closePhysioTherapyDeleteModal = () => {
    setShowPhysioTherapyDeleteModal(false);
    setPhysioTherapyDeleteId(null);
  }

  const onDeletePhysioTherapyRow = (id) => {
    setShowPhysioTherapyDeleteModal(true);
    setPhysioTherapyDeleteId(id);
  }

  const deletePhysioTherapyeRow = (data) => {
    AHMIS.delete(API_URL.physiotherapySewaRegister + "/" + physioTherapyDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowPhysioTherapyDeleteModal(false);
          setPhysioTherapyDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=8&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }


  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  useEffect(() => {
    physiotherapyPendingPatient()
  }, [])

  const physiotherapyPendingPatient = () => {
    let url = `${API_URL.pendingPatient}?sewaType=${"8"}&fiscalYear=${DateUtils.getCurrentFiscalYear()}&registerConstant=${PHYSIOTHERAPY}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setPendingPatientList(jsondata.data);
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="फिजियोथेरापी सेवा रजिष्टर"
          handleModalOpen={openPhysioTherapyModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getPhysioTherapyData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showPhysioTherapyModal}
        onModalSubmit={handleSubmit(submitPhysioTherapyRegister)}
        onModalClose={closePhysioTherapyModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            registerConstant={PHYSIOTHERAPY}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="sentFromOrganizationName"
              autoComplete="true"
              defaultValue={modalDefaultValues?.sentFromOrganizationName}
              fullWidth
              {...register("sentFromOrganizationName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="nameInstitutionAttended"
              autoComplete="true"
              defaultValue={modalDefaultValues?.nameInstitutionAttended}
              fullWidth
              {...register("nameInstitutionAttended")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      {
        pendingPatientList?.size > 0 &&
        <Box mt={2}>
          <PendingPatientList
            pendingPatientList={pendingPatientList}
            showModal={setShowPhysioTherapyModal}
            modalDefaultValues={setModalDefaultValues}
            setValue={setValue}
          />
        </Box>
      }
      <DeleteRegisterRecordConformation
        showDeleteModal={showPhysioTherapyDeleteModal}
        closeDeleteModel={closePhysioTherapyDeleteModal}
        deleteRecord={deletePhysioTherapyeRow}
      />
      <PhysiotherapyRegister
        onEditRow={physioTherpyEdit}
        tableData={physiotherapyDataTable}
        deleteRow={onDeletePhysioTherapyRow}
        handleChangePage={handleChangePage}
        page={page}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default PhysiotherapyForm
