
import { makeStyles } from '@mui/styles'

const styles = makeStyles(theme => ({
  pendingPatientListContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "scroll"
  },
  totalRemainingCount: {
    borderRadius: 0,
    marginRight: theme.spacing(.5),
    fontWeight: 500
  },
  patientChip: {
    marginRight: theme.spacing(.5)
  },
}));
export default styles;