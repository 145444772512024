import axios from "axios";
import { AppUtils } from "../utils/appUtils";
import { SessionStorage } from "../utils/storage/sessionStorage";
import { IS_SESSION_EXPIRED } from "../utils/constants";
import { AppMisc } from "../misc/appMisc";

export const LOCAL_CONSTANTS = {
  BASE_URL: "http://localhost:8093/api/"
};

export const DEV_CONSTANTS = {
  BASE_URL: "https://ahmis.asktrek.com/api/"
};

export const PROD_CONSTANTS = {
  BASE_URL: ""
};

export const BASE_URL = DEV_CONSTANTS.BASE_URL;

export const API_URL = {
  login: BASE_URL + "login",
  municipality: BASE_URL + "municipality",
  logOut: BASE_URL + "logout",
  resetPassword: BASE_URL + "public/reset-password",
  forgetPassword: BASE_URL + "public/forget-password",
  changePassword: BASE_URL + "change-password",
  applicationSetting: BASE_URL + "user/application-setting",
  user: BASE_URL + "user",
  profile: BASE_URL + "user/user-profile",
  healthClient: BASE_URL + "health",
  municipalityUsers: BASE_URL + "user/municipality-users",
  municipalityHealthClients: BASE_URL + "health/municipality-health-clients",
  mulDartaaRegister: BASE_URL + "user/mul-dartaa-register",
  mulDartaaNumber: BASE_URL + "user/mul-dartaa-register/mul-dartaa-numbers",
  outPatient: BASE_URL + "user/out-patient",
  medicineDistribution: BASE_URL + "user/medicine-distributor",
  medicineList: BASE_URL + "user/add-medicine",
  panchakarmaRegister: BASE_URL + "user/panchakarma-registration",
  ksharsutraRegister: BASE_URL + "user/ksharsutra-register",
  therapeuticSewaRegister: BASE_URL + "user/therapeutic-register",
  jesthanagarik: BASE_URL + "user/jesthanagarik-register",
  gaungharsahari: BASE_URL + "user/gaunghar-sahari",
  admission: BASE_URL + "user/admission-register",
  discharge: BASE_URL + "user/discharge-register",
  emergency: BASE_URL + "user/emergency-service",
  sewaDartaaNumber: BASE_URL + "user/common-method/dartaaNumber",
  stanpaiAama: BASE_URL + "user/stanpai-aama",
  panchaKarmaRegistrationService: BASE_URL + "user/panchakarma-registration-service",
  bityaBibaran: BASE_URL + "user/bitya-bibaran",
  karmaBibaran: BASE_URL + "user/employee-registration",
  getLatestDartaaNumber: BASE_URL + "user/mul-dartaa-register/latestMulDartaNumber",
  getPanchaKarmaPendingPatient: BASE_URL + "user/panchakarma-registration/getPendingPatient",
  getOpdPendingPatient: BASE_URL + "user/out-patient/getPendingPatient",
  shalyaChikitsa: BASE_URL + "user/shalyaChikitsa-registration",
  shalyaChikitsaService: BASE_URL + "user/ShalyaChikitsa-registration-service",
  acupunctureSewa: BASE_URL + "user/acupunctureSewa-registration",
  acupunctureSewaService: BASE_URL + "user/acupunctureSewa-registration-service",
  physiotherapySewaRegister: BASE_URL + "user/physiotherapySewa-registration",
  physiotherapySewaService: BASE_URL + "user/physiotherapySewa-registration-service",
  yogSewaRegister: BASE_URL + "user/yogSewa-registration",
  yogSewaService: BASE_URL + "user/yogSewa-registration-service",
  anatmakTathaAbhiyanamulakaYog: BASE_URL + "user/prabardhanatmak-abhiyan-yogSewa-register",
  homeoPathy: BASE_URL + "user/homeopathy_register",
  nagrikAArogya: BASE_URL + "user/nagarik-arogya-sewa",
  nonCommunicable: BASE_URL + "user/non-communicable-and-mental-health",
  pendingPatient: BASE_URL + "user/common-method/pending-patient-list",
  stanpaiAamaThaShishuVivaranService: BASE_URL + "user/stanpai-aama-sishu-service",
  serviceTakenCount: BASE_URL + "user/common-method/get-service-taken-time",
  therapeuticYogService: BASE_URL + "user/therapeutic-yog-service",
  // report
  monthlyProgressReport: BASE_URL + "user/report/organization-progress-report",
  pradhanKarmaReport: BASE_URL + "user/report/pancha-karma"
}

const AHMIS = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  withCredentials: true,
});

AHMIS.interceptors.request.use(
  config => {
    if (!AppMisc.hideFullBodyLoader(config.url, config.method)) {
      document.body["children"].root.classList.add('body-loader');
    }
    return config;
  },
  error => Promise.reject(error)
);

AHMIS.interceptors.response.use(
  response => {
    document.body["children"].root.classList.remove('body-loader');
    return response;
  },
  error => {
    document.body["children"].root.classList.remove('body-loader');
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        AppUtils.removeUserRef();
        SessionStorage.setItem(IS_SESSION_EXPIRED, "true");
        window.location.href = "/";
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default AHMIS;