import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import CustomModal from '../../../components/modal/CustomModal';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import AHMIS, { API_URL } from '../../../api/api';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { INVALID_BLOOD_PRESSURE_MESSAGE, MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import { useForm } from 'react-hook-form';
import { DateUtils } from '../../../utils/dateUtils';
import { ERROR, PHYSIO_THERAPY_CODE, REQUIRED_FIELD, ROW_PER_PAGE, SALYACHIKITA, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import ShalyaChikitsaRegister from '../../users/components/register/shalya-chikitsa-register/ShalyaChikitsaRegister';
import AddAlertMessage from '../../../components/alert/Alert';
import { AppMisc } from '../../../misc/appMisc';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect';
import PendingPatientList from '../../../components/pending-patient-list/PendingPatientList';

const ShalyaChikitsaForm = () => {
  const [modalTitle, setModalTitle] = useState("शल्य चिकित्सा रजिस्टरमा नयाँ रेकर्ड थप्नुहोस् ।");
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [getTableData, setGetTableData] = useState(false);
  const [showShalyaChikitsaModal, setShowShalyaChikitsaModal] = useState(false)
  const [page, setPage] = useState(0);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const districtOptions = AppMisc.getDistrictOptions();
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [shalyaChikitsTableData, setShalyaChikitsTableData] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [shalyaChikitsaDeleteId, setShalyaChikitsaDeleteId] = useState(null);
  const [showShalyaChikitsaDeleteModal, setShowShalyaChikitsaDeleteModal] = useState(false);
  const [pendingPatientList, setPendingPatientList] = useState([])

  const openShalyaChikitsaModal = () => {
    setShowShalyaChikitsaModal(true);
  }


  useEffect(() => {
    if (showShalyaChikitsaModal) {
      reset()
    }
  }, [showShalyaChikitsaModal])

  const closeShalyaChikitsaModal = () => {
    reset();
    setShowShalyaChikitsaModal(false);
    setModalDefaultValues()
  }

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getShalyaChikisa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const submitShalyaChikisaRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.shalyaChikitsa, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeShalyaChikitsaModal();
          setGetTableData(true);
          salyachikitsaPendingPatient()
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    register("sewaCode");
  }, [register]);


  const getShalyaChikisa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.shalyaChikitsa}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setShalyaChikitsTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const shalyaChikisaEdit = (id) => {
    AHMIS.get(API_URL.shalyaChikitsa + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowShalyaChikitsaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeShalyaChikitsaDeleteModal = () => {
    setShowShalyaChikitsaDeleteModal(false);
    setShalyaChikitsaDeleteId(null);
  }

  const onDeleteShalyaChikitsaRow = (id) => {
    setShowShalyaChikitsaDeleteModal(true);
    setShalyaChikitsaDeleteId(id);
  }

  const deleteShalyaChikitsaRow = (data) => {
    AHMIS.delete(API_URL.shalyaChikitsa + "/" + shalyaChikitsaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowShalyaChikitsaDeleteModal(false);
          setShalyaChikitsaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  useEffect(() => {
    salyachikitsaPendingPatient()
  }, [])

  const salyachikitsaPendingPatient = () => {
    let url = `${API_URL.pendingPatient}?sewaType=${"3"}&fiscalYear=${DateUtils.getCurrentFiscalYear()}&registerConstant=${SALYACHIKITA}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setPendingPatientList(jsondata.data);
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="शल्य चिकित्सा सेवा रजिष्टर"
          handleModalOpen={openShalyaChikitsaModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getShalyaChikisa}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
        <CustomModal
          title={modalTitle}
          showModal={showShalyaChikitsaModal}
          onModalSubmit={handleSubmit(submitShalyaChikisaRegister)}
          onModalClose={closeShalyaChikitsaModal}
          fullScreen
        >
          <Grid container spacing={2} alignItems="center">
            <MulDartaaSelect
              mulDartaaOptions={mulDartaaOptions}
              defaultValues={modalDefaultValues}
              register={register}
              handleSubmit={handleSubmit}
              setValue={setValue}
              errors={errors}
              watch={watch}
              handleSelectedDartaaMitiChange={handleDateChange}
              registerConstant={SALYACHIKITA}
            />
          </Grid>
          <Box marginTop={2}>
            <Typography variant="h6">General Examination</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="रक्तचाप (Blood Pressure)"
                size="small"
                variant="outlined"
                name="bloodPressure"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.bloodPressure}
                {...register("bloodPressure", {
                  pattern: /^(0|[1-9][0-9]*)\/(0|[1-9][0-9]*)$/,
                  required: true
                })}
              />
              {errors.bloodPressure && errors.bloodPressure.type === "pattern" && <span className="error-message">{INVALID_BLOOD_PRESSURE_MESSAGE}</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="प्रकृति"
                size="small"
                variant="outlined"
                name="nature"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.nature}
                {...register("nature")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="तौल (KG)"
                size="small"
                variant="outlined"
                name="weight"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.weight}
                {...register("weight")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="रोग"
                size="small"
                variant="outlined"
                name="disease"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.disease}
                {...register("disease")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="एफविएस"
                size="small"
                variant="outlined"
                name="fbs"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.fbs}
                {...register("fbs")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="परीक्षण"
                size="small"
                variant="outlined"
                name="test"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.test}
                {...register("test")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CustomReactSelect
                label="सेवा"
                name="sewaCode"
                variant="outlined"
                options={PHYSIO_THERAPY_CODE}
                onChange={handleSelectChange}
                defaultValue={modalDefaultValues?.therapyCode}
              />
              {errors?.therapyCode && errors.therapyCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="परिक्षण सल्लाह"
                size="small"
                variant="outlined"
                name="testAdvice"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.testAdvice}
                {...register("testAdvice")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="जटिलता"
                size="small"
                variant="outlined"
                name="complexity"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.complexity}
                {...register("complexity")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="अन्य"
                size="small"
                variant="outlined"
                name="anay"
                autoComplete="true"
                fullWidth
                defaultValue={modalDefaultValues?.anay}
                {...register("anay")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <TextField
                label="आएको संस्थाको नाम"
                size="small"
                variant="outlined"
                name="sentFromOrganizationName"
                autoComplete="true"
                defaultValue={modalDefaultValues?.institutionName}
                fullWidth
                {...register("sentFromOrganizationName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <TextField
                label="गएको संस्थाको नाम"
                size="small"
                variant="outlined"
                name="sentToOrganizationName"
                autoComplete="true"
                defaultValue={modalDefaultValues?.institutionAttended}
                fullWidth
                {...register("sentToOrganizationName")}
              />
            </Grid>
          </Grid>
        </CustomModal>
        {
          pendingPatientList?.size > 0 &&
          <Box mt={2}>
            <PendingPatientList
              pendingPatientList={pendingPatientList}
              showModal={setShowShalyaChikitsaModal}
              modalDefaultValues={setModalDefaultValues}
              setValue={setValue}
            />
          </Box>
        }
      </Box>
      <DeleteRegisterRecordConformation
        showDeleteModal={showShalyaChikitsaDeleteModal}
        closeDeleteModel={closeShalyaChikitsaDeleteModal}
        deleteRecord={deleteShalyaChikitsaRow}
      />
      <ShalyaChikitsaRegister
        onEditRow={shalyaChikisaEdit}
        tableData={shalyaChikitsTableData}
        deleteRow={onDeleteShalyaChikitsaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default ShalyaChikitsaForm;
