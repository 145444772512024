import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

const EmergencyReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">नसर्ने रोगको  प्रतिबेदन </Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="emergency-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={2}>विवरण</TableCell>
              <TableCell colSpan={3}>मृत्यु</TableCell>
              <TableCell colSpan={3}>शुल्क छुट</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>महिला</TableCell>
              <TableCell>पुरूष</TableCell>
              <TableCell>अन्य</TableCell>
              <TableCell>महिला</TableCell>
              <TableCell>पुरूष</TableCell>
              <TableCell>अन्य</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>&lt; ४८ घण्टा</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>>४८ घण्टा</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पूर्ण शूल्क छुट जना</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>आशिक सुल्क छुट जना</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default EmergencyReport
