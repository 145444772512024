import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import { useForm } from 'react-hook-form'
import AHMIS, { API_URL } from '../../../api/api'
import AddAlertMessage from '../../../components/alert/Alert'
import { ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants'
import CustomModal from '../../../components/modal/CustomModal'
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect'
import { SessionStorage } from '../../../utils/storage/sessionStorage'
import { MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms'
import { DateUtils } from '../../../utils/dateUtils'
import { AppMisc } from '../../../misc/appMisc'
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation'
import NcdRegister from '../../users/components/register/Ncd-register/NcdRegister'

const NcdForm = () => {
  const [modalTitle, setModalTitle] = useState("नसर्ने रोग तथा मानसिक स्वास्थ्य सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्।");
  const [showNcdModal, setShowNcdModal] = useState(false)
  const [getTableData, setGetTableData] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [ncdDataTable, setNcdDataTable] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [showNcdDeleteModal, setShowNcdDeleteModal] = useState(false);
  const [ncdDeleteId, setNcdDeleteId] = useState(null);
  const districtOptions = AppMisc.getDistrictOptions();
  const [page, setPage] = useState(0);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();

  const openNcdModal = () => {
    setShowNcdModal(true);
  }

  const closeNcdModal = () => {
    reset();
    setShowNcdModal(false);
    setModalDefaultValues()
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const submitNcdForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.nonCommunicable, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeNcdModal()
          setGetTableData(true)
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const ncdEdit = (id) => {
    AHMIS.get(API_URL.nonCommunicable + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data?.data);
        setShowNcdModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeNcdDeleteModal = () => {
    setShowNcdDeleteModal(false);
    setNcdDeleteId(null);
  }

  const onDeleteNcdRow = (id) => {
    setShowNcdDeleteModal(true);
    setNcdDeleteId(id);
  }

  const deleteNcdPatientRow = (data) => {
    AHMIS.delete(API_URL.nonCommunicable + "/" + ncdDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowNcdDeleteModal(false);
          setNcdDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const getNcdData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.nonCommunicable}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setNcdDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getNcdData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=12&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="नसर्ने रोग तथा मानसिक स्वास्थ्य सेवा रजिष्टर"
          handleModalOpen={openNcdModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getNcdData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showNcdModal}
        onModalSubmit={handleSubmit(submitNcdForm)}
        onModalClose={closeNcdModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="ICD Code"
              size="small"
              variant="outlined"
              name="icdCode"
              fullWidth
              defaultValue={modalDefaultValues?.investigation}
              {...register("icdCode")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="निदानात्मक परीक्षण"
              size="small"
              variant="outlined"
              name="diagnosisTest"
              fullWidth
              defaultValue={modalDefaultValues?.diagnosisTest}
              {...register("diagnosisTest")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सम्भावित निदान"
              size="small"
              variant="outlined"
              name="possibleDiagnosis"
              fullWidth
              defaultValue={modalDefaultValues?.possibleDiagnosis}
              {...register("possibleDiagnosis")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="औषधि"
              size="small"
              variant="outlined"
              name="medicine"
              fullWidth
              defaultValue={modalDefaultValues?.medicine}
              {...register("medicine")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="मनोसामाजिक परामर्श"
              size="small"
              variant="outlined"
              name="died"
              fullWidth
              defaultValue={modalDefaultValues?.died}
              {...register("died")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परामर्श"
              size="small"
              variant="outlined"
              name="paramarsa"
              fullWidth
              defaultValue={modalDefaultValues?.paramarsa}
              {...register("paramarsa")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="अन्य"
              size="small"
              variant="outlined"
              name="others"
              fullWidth
              defaultValue={modalDefaultValues?.others}
              {...register("others")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="मृत्यु भएको"
              size="small"
              variant="outlined"
              name="mrrtyuBhaeko"
              fullWidth
              defaultValue={modalDefaultValues?.mrrtyuBhaeko}
              {...register("mrrtyuBhaeko")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उपचार नियमितता"
              size="small"
              variant="outlined"
              name="upacaraNiyamitata"
              fullWidth
              defaultValue={modalDefaultValues?.upacaraNiyamitata}
              {...register("upacaraNiyamitata")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सुधारको अवस्था"
              size="small"
              variant="outlined"
              name="sudharakoAvastha"
              fullWidth
              defaultValue={modalDefaultValues?.sudharakoAvastha}
              {...register("sudharakoAvastha")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रेषण भइ आएको संस्था/ व्यक्तिको नाम  र पेशा"
              size="small"
              variant="outlined"
              name="sendFromOtherOrganization"
              fullWidth
              defaultValue={modalDefaultValues?.sendFromOtherOrganization}
              {...register("sendFromOtherOrganization")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाको प्रकार"
              size="small"
              variant="outlined"
              name="sewaType"
              fullWidth
              defaultValue={modalDefaultValues?.sewaType}
              {...register("sewaType")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="लैङ्गिक हिंसा"
              size="small"
              variant="outlined"
              name="genderVoilence"
              fullWidth
              defaultValue={modalDefaultValues?.genderVoilence}
              {...register("genderVoilence")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="institutionName"
              autoComplete="true"
              defaultValue={modalDefaultValues?.institutionName}
              fullWidth
              {...register("institutionName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="institutionAttended"
              autoComplete="true"
              defaultValue={modalDefaultValues?.institutionAttended}
              fullWidth
              {...register("institutionAttended")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showNcdDeleteModal}
        closeDeleteModel={closeNcdDeleteModal}
        deleteRecord={deleteNcdPatientRow}
      />
      <NcdRegister
        onEditRow={ncdEdit}
        tableData={ncdDataTable}
        deleteRow={onDeleteNcdRow}
        handleChangePage={handleChangePage}
        page={page}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default NcdForm;