import { Box, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../../components/register-header/RegisterHeader'
import { AppUtils } from '../../../../utils/appUtils';
import { ERROR, ID, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, THERAPY_CODE } from '../../../../utils/constants';
import AHMIS, { API_URL } from '../../../../api/api';
import AddAlertMessage from '../../../../components/alert/Alert';
import { useForm } from 'react-hook-form';
import CustomModal from '../../../../components/modal/CustomModal';
import NepaliCalender from '../../../../components/nepali-calender';
import CustomReactSelect from '../../../../components/custom-react-select/CustomReactSelect';
import DeleteRegisterRecordConformation from '../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import AcupunctureSewaBibaranRegister from '../../../users/components/register/acupuncture-register/helper/AcupunctureSewaBibaranRegister';

const AcupunctureSewaBibaranForm = () => {
  const [modalTitle, setModalTitle] = useState("अकुपन्चर चिकित्सा सेवा राजिस्टर  नयाँ रेकर्ड थप्नुहोस्");
  const [showAcupunctureBibaranModal, setShowAcupunctureBibaranModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [acupunctureBibaranSewaBibaranTableData, setAcupunctureBibaranSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [showAcupunctureBibaranDeleteModal, setShowAcupunctureBibaranDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [acupunctureBibaranDeleteId, setAcupunctureBibaranDeleteId] = useState(null);
  const acupunctureRegisterId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    showAcupunctureBibaranModal && reset();
  }, [reset, showAcupunctureBibaranModal]);

  useEffect(() => {
    register("therapyCode")
  }, [register])

  const openAcupunctureBibaranModal = () => {
    setShowAcupunctureBibaranModal(true);
  };

  const closeAcupunctureBibaranModal = () => {
    setShowAcupunctureBibaranModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitAcupunctureBibaranRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    const url = `${API_URL.acupunctureSewaService}?acupunctureServiceRegistrationId=${acupunctureRegisterId}`;
    AHMIS.post(url, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeAcupunctureBibaranModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getAcupunctureBibaranSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.acupunctureSewaService}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&acupunctureServiceRegistrationId=${acupunctureRegisterId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setAcupunctureBibaranSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const acupunctureBibaranSewaEdit = (id) => {
    AHMIS.get(API_URL.acupunctureSewaService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowAcupunctureBibaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeAcupunctureBibaranDeleteModal = () => {
    setShowAcupunctureBibaranDeleteModal(false);
    setAcupunctureBibaranDeleteId(null);
  }

  const onDeleteAcupunctureBibaranRow = (id) => {
    setShowAcupunctureBibaranDeleteModal(true);
    setAcupunctureBibaranDeleteId(id);
  }

  const deleteAcupunctureBibaranSewaRow = (data) => {
    AHMIS.delete(API_URL.acupunctureSewaService + "/" + acupunctureBibaranDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowAcupunctureBibaranDeleteModal(false);
          setAcupunctureBibaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getAcupunctureBibaranSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="अकुपन्चर चिकित्सा सेवा राजिस्टर सेवा विवरण "
          handleModalOpen={openAcupunctureBibaranModal}
          getRegisterData={getAcupunctureBibaranSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showAcupunctureBibaranModal}
        onModalSubmit={handleSubmit(submitAcupunctureBibaranRegister)}
        onModalClose={closeAcupunctureBibaranModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="मिति"
              name="dartaaMiti"
              defaultDate={modalDefaultValues?.dartaaMiti}
              onChange={handleDateChange}
            />
            {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="थेरापी कोड"
              name="therapyCode"
              variant="outlined"
              options={THERAPY_CODE}
              onChange={handleSelectChange}
              defaultValue={modalDefaultValues?.therapyCode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complexity"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complexity}
              {...register("complexity")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण /सल्लाह"
              size="small"
              variant="outlined"
              name="testing"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("testing")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="कैफियत"
              size="small"
              variant="outlined"
              name="remarks"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("remarks")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showAcupunctureBibaranDeleteModal}
        closeDeleteModel={closeAcupunctureBibaranDeleteModal}
        deleteRecord={deleteAcupunctureBibaranSewaRow}
      />
      <AcupunctureSewaBibaranRegister
        onEditRow={acupunctureBibaranSewaEdit}
        tableData={acupunctureBibaranSewaBibaranTableData}
        deleteRow={onDeleteAcupunctureBibaranRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default AcupunctureSewaBibaranForm;