import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { Box, Grid, TextField, Tooltip } from "@mui/material";
import AHMIS, { API_URL } from "../../api/api";
import AddAlertMessage from "../alert/Alert";
import CustomReactSelect from "../custom-react-select/CustomReactSelect";
import NepaliCalender from "../nepali-calender";
import { AppMisc } from "../../misc/appMisc";
import { CASTE_CODES, ERROR, GENDER_OPTIONS, HTTP_STATUS_CODES, PATIENT_TYPES, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../utils/constants";
import { AGE_UNITS, MUL_DARTA_NUMBERS_LIST } from "../../utils/constants/forms";
import { SessionStorage } from "../../utils/storage/sessionStorage";
import { DateUtils } from "../../utils/dateUtils";

export default function MulDartaaSelect({
  mulDartaaOptions,
  defaultValues,
  register,
  handleSelectedDartaaMitiChange,
  handleSubmit,
  setValue,
  errors,
  watch,
  ...props }) {
  const [shrinkLabel, setShrinkLabel] = useState(false);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [clearPalika, setClearPalika] = useState(true);
  const [readOnly, setReadOnly] = useState(true)
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [isDartaaMitiRegistered, setIsDartaaMitiRegistered] = useState(false);
  const [mulDartaaLabel, setMulDartaaLabel] = useState();
  const [mulDartaOptions, setMulDartaOptions] = useState([]);

  useEffect(() => {
    mulDartaaOptions?.length && setMulDartaOptions(mulDartaaOptions)
  }, [mulDartaaOptions])

  const districtOptions = AppMisc.getDistrictOptions();

  const watchFields = watch(["foreigner", "dartaaMiti", "mulDartaaNumber"]);

  const handleMulDartaaChange = (name, value, options) => {
    setReadOnly(true);
    if (!props.villageClinic) {
      let mulDartaaNumbers = SessionStorage.getItem(MUL_DARTA_NUMBERS_LIST);

      if (mulDartaaNumbers) {
        let muldartaaNumberInfo = mulDartaaNumbers.find(obj => obj.mulDartaaNumber == value);
        muldartaaNumberInfo ? updatePatientDetails(muldartaaNumberInfo) : getMuldartaaDetailsFromId(options.id);
      } else {
        getMuldartaaDetailsFromId(options.id)
      }
    } else {
      props.getDetailsByVillageClinicDartaaNumber(value);
    }
  };

  useEffect(() => {
    if (props.updateDetailsData) {
      updatePatientDetails(props.updateDetailsData);
    }
  }, [props.updateDetailsData])

  const handleMulDartaaAsyncChange = (value) => {
    setMulDartaaLabel(value);
    updatePatientDetails(value);
  }

  useEffect(() => {
    setModalDefaultValues({ ...defaultValues });
  }, [defaultValues])

  const handleCustomSelectChange = (name, value) => {
    setValue(name, value);
  }

  const handlePalikaChange = (name, value) => {
    setClearPalika(false);
    setValue(name, value);
  }

  const handleDistrictSelectChange = (name, value, options) => {
    setClearPalika(true);
    setPalikaOptions(options.palikas);
    setValue(name, value)
  }

  useEffect(() => {
    register(props.mulDartaaNumber, { required: props.isMulDartaaNumberRequired });
    register("casteCode", { required: true });
    register("patientType")
    register("gender", { required: true });
    register("ageUnit", { required: true });
    register("palikaName", { required: !watchFields.foreigner });
    register("foreignAddress");
    register("foreigner");
    register("oldVillageClinicDartaaNumber");
    register("oldVillageClinicObjectId");
  }, [register, props.villageClinic])

  useEffect(() => {
    register("district", { required: !watchFields.foreigner });
  }, [watchFields.foreigner, register])

  const handleDartaaMitiChange = (name, dateInMilli) => {
    if (!isDartaaMitiRegistered && !props.villageClinic) {
      register("dartaaMiti", { required: true });
      setIsDartaaMitiRegistered(true);
    }
    setValue(name, dateInMilli);
    handleSelectedDartaaMitiChange && handleSelectedDartaaMitiChange(name, dateInMilli);
  }

  useEffect(() => {
    !props.isPresentFiscalYear ? setReadOnly(false) : setReadOnly(true);
    !props.isPresentFiscalYear && setShrinkLabel(true);
  }, [props.isPresentFiscalYear])

  const updatePatientDetails = (patientDetails) => {
    if (patientDetails) {
      setShrinkLabel(true);
      patientDetails.palikaName = patientDetails.palikaName && AppMisc.getMunicipalityName(patientDetails.palikaName);

      setValue(props.dartaaNumberName, patientDetails.mulDartaaNumber || patientDetails.mulDartaaNumber);
      setValue("patientFirstName", patientDetails.patientFirstName);
      setValue("patientLastName", patientDetails.patientLastName);
      setValue("age", patientDetails.age);
      setValue("palikaName", patientDetails.palikaName);
      setValue("wardNumber", patientDetails.wardNumber);
      setValue("gaunOrTole", patientDetails.gaunOrTole);
      setValue("phoneNumber", patientDetails.phoneNumber);
      setValue("foreigner", patientDetails.foreigner);
      setValue("otherCountry", patientDetails.otherCountry);
      setValue("nationalCardNumber", patientDetails.nationalCardNumber);
      patientDetails?.sewaDartaaNumber && setValue("sewaDartaaNumber", patientDetails.sewaDartaaNumber);
      patientDetails.oldVillageClinicDartaaNumber && setValue("oldVillageClinicDartaaNumber", patientDetails.oldVillageClinicDartaaNumber)
      patientDetails.oldVillageClinicObjectId && setValue("oldVillageClinicObjectId", patientDetails.oldVillageClinicObjectId)
      props?.getMulDartaaNumberAndFiscalYear && props.getMulDartaaNumberAndFiscalYear(patientDetails.dartaaNumber, patientDetails.fiscalYear)

      setModalDefaultValues(prev => ({
        ...prev,
        casteCode: patientDetails.casteCode,
        ageUnit: patientDetails.ageUnit,
        gender: patientDetails.gender,
        district: patientDetails.district,
        palikaName: patientDetails.palikaName
      }));
    }
  }

  useEffect(() => {
    if (defaultValues?.id && props.showAsyncSelect) {
      setMulDartaaLabel({ dartaaNumber: defaultValues.mulDartaaNumber, patientFirstName: defaultValues.patientFirstName, patientLastName: defaultValues.patientLastName })
      updatePatientDetails({ ...defaultValues });
    }
  }, [defaultValues, props.showAsyncSelect])

  const getMuldartaaDetailsFromId = (id) => {
    if (id) {
      AHMIS.get(API_URL.mulDartaaDetailsFromId + "/" + id)
        .then(response => {
          if (response.status === HTTP_STATUS_CODES.OK) {
            updatePatientDetails(response.data);
          }
        })
        .catch(error => {
          AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        });
    }
  }

  useEffect(() => {
    if (props.registerConstant && !defaultValues?.id) {
      getSewaDartaNumber()
    }
  }, [props.registerConstant, !defaultValues?.id])

  const getSewaDartaNumber = () => {
    let url = `${API_URL.sewaDartaaNumber}?register=${props.registerConstant}&fiscalYear=${DateUtils.getCurrentFiscalYear()}`;
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setValue("sewaDartaaNumber", jsondata.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  // TODO-Krishna:Remove async search param and append all patient list by default.

  const _loadSuggestions = async (searchParam, callback) => {
    if (searchParam && watchFields.dartaaMiti) {
      let url = `${API_URL.mulDartaaRegister}/mul-dartaa-numbers?sewaType=${props.sewaType}&searchParam=${searchParam}&fiscalYear=${DateUtils.getFiscalYearFromDateMilli(watchFields.dartaaMiti)}`
      if (props.registerConstant) {
        url += `&registerConstant=${props.registerConstant}`
      }
      try {
        let response = await AHMIS.get(url);
        setMulDartaOptions(response.data);
        callback && callback(response.data || [])
      } catch (error) {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      }
    }
  }

  const loadOptions = debounce(_loadSuggestions, 1000);

  return (
    <>
      {!props.villageClinic &&
        (
          <Grid item xs={12} sm={6} md={2}>
            <Tooltip title="दर्ता मिति" placement="top" arrow>
              <Box>
                <NepaliCalender
                  id="dartaa-miti"
                  name="dartaaMiti"
                  onChange={handleDartaaMitiChange}
                  label="दर्ता मिति"
                  defaultDate={modalDefaultValues.dartaaMiti}
                  showDefaultDate
                />
              </Box>
            </Tooltip>
            {errors.dartaaMiti && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
        )
      }
      {props.showAsyncSelect ?
        (<Grid item xs={12} sm={6} md={2}>
          <AsyncSelect
            cacheOptions
            defaultOptions={mulDartaOptions}
            className="select-sm"
            classNamePrefix="react-select"
            size="small"
            placeholder="मुल दर्ता नं./नाम टाइप गर्नुहोस् *"
            value={mulDartaaLabel}
            getOptionLabel={e => `${e.dartaaNumber} (${e.patientFirstName} ${e.patientLastName})`}
            getOptionValue={e => e.dartaaNumber}
            loadOptions={loadOptions}
            onChange={handleMulDartaaAsyncChange}
          />
          {errors[props.dartaaNumberName] && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>) : (
          <Grid item xs={12} sm={6} md={2}>
            <CustomReactSelect
              label={props.dartaaNumberLabel}
              options={mulDartaOptions}
              defaultValue={modalDefaultValues.mulDartaaNumber}
              name={props.mulDartaaNumber}
              onChange={handleMulDartaaChange}
              isClearable={false}
              creatable
            />
            {errors[props.dartaaNumberName] && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>)}
      {props.registerConstant && <Grid item xs={12} sm={6} md={2}>
        <TextField
          label={props.sewaDartaaNumberLabel}
          name="sewaDartaaNumber"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          {...register("sewaDartaaNumber", { required: true })}
          defaultValue={defaultValues?.sewaDartaaNumber}
          size="small"
          fullWidth
        />
        {errors.sewaDartaaNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>}
      <Grid item xs={12} sm={6} md={2}>
        <CustomReactSelect
          label="सेवाग्राहीको किसिम"
          name="patientType"
          options={PATIENT_TYPES}
          onChange={handleCustomSelectChange}
          defaultValue={modalDefaultValues?.patientType}
        />
        {errors?.patientType && errors.patientType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          InputProps={{ readOnly: readOnly }}
          label={props.patientFirstNameLabel}
          size="small"
          name="patientFirstName"
          variant="outlined"
          {...register("patientFirstName", { required: true })}
          defaultValue={defaultValues?.patientFirstName}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        {errors.patientFirstName && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          InputProps={{ readOnly: readOnly }}
          label={props.patientLastNameLabel}
          size="small"
          name="patientLastName"
          defaultValue={defaultValues?.patientLastName}
          variant="outlined"
          {...register("patientLastName", { required: true })}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        {errors.patientLastName && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomReactSelect
          label={props.casteCodeLabel}
          name="casteCode"
          defaultValue={modalDefaultValues.casteCode}
          options={CASTE_CODES}
          onChange={handleCustomSelectChange}
          isDisabled={readOnly}
        />
        {errors.casteCode && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomReactSelect
          label={props.genderLabel}
          name="gender"
          defaultValue={modalDefaultValues.gender}
          options={GENDER_OPTIONS}
          onChange={handleCustomSelectChange}
          isDisabled={readOnly}
        />
        {errors.gender && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          label={props.ageLabel}
          size="small"
          name="age"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues?.age}
          {...register("age", { required: true })}
          InputProps={{ readOnly: readOnly }}
          fullWidth
        />
        {errors.age && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomReactSelect
          label="उमेर वर्ष वा महिना"
          name="ageUnit"
          defaultValue={modalDefaultValues.ageUnit}
          options={AGE_UNITS}
          isDisabled={readOnly}
          isClearable={false}
          onChange={handleCustomSelectChange}
        />
        {errors.ageUnit && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomReactSelect
          name="district"
          label={props.districtLabel}
          defaultValue={modalDefaultValues.district}
          options={districtOptions}
          onChange={handleDistrictSelectChange}
          isDisabled={readOnly}
          isClearable={false}
        />
        {errors.district && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CustomReactSelect
          name="palikaName"
          label={props.palikaNameLabel}
          defaultValue={modalDefaultValues.palikaName}
          options={palikaOptions}
          onChange={handlePalikaChange}
          isDisabled={readOnly}
          clearSelect={clearPalika}
        />
        {errors.palikaName && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          name="wardNumber"
          label={props.wardNumberLabel}
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues?.wardNumber}
          variant="outlined"
          {...register("wardNumber", { required: true })}
          size="small"
          InputProps={{ readOnly: readOnly }} gen
          fullWidth
        />
        {errors.wardNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          name="gaunOrTole"
          label={props.gaunOrToleLabel}
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues?.gaunOrTole}
          variant="outlined"
          size="small"
          {...register("gaunOrTole", { required: true })}
          fullWidth
        />
        {errors.gaunOrTole && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          name="phoneNumber"
          label={props.phoneNumberLabel}
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues?.phoneNumber}
          variant="outlined"
          {...register("phoneNumber")}
          size="small"
          InputProps={{ readOnly: readOnly }}
          fullWidth
          isDisabled={readOnly}
        />
        {errors.phoneNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          name="otherCountry"
          label={props.otherCountry}
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues?.otherCountry}
          variant="outlined"
          {...register("otherCountry")}
          size="small"
          InputProps={{ readOnly: readOnly }}
          fullWidth
          isDisabled={readOnly}
        />
        {errors.otherCountry && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <TextField
          name="nationalCardNumber"
          label={props.nationalCardNumber}
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues?.nationalCardNumber}
          variant="outlined"
          {...register("nationalCardNumber")}
          size="small"
          InputProps={{ readOnly: readOnly }}
          fullWidth
          isDisabled={readOnly}
        />
        {errors.nationalCardNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
      </Grid>
    </>
  );
}

MulDartaaSelect.propTypes = {
  dartaaNumberLabel: PropTypes.string,
  sewaDartaaNumberLabel: PropTypes.string,
  dartaaNumberName: PropTypes.string,
  patientFirstNameLabel: PropTypes.string,
  patientLastNameLabel: PropTypes.string,
  casteCodeLabel: PropTypes.string,
  genderLabel: PropTypes.string,
  ageLabel: PropTypes.string,
  districtLabel: PropTypes.string,
  palikaNameLabel: PropTypes.string,
  wardNumberLabel: PropTypes.string,
  gaunOrToleLabel: PropTypes.string,
  phoneNumberLabel: PropTypes.string,
  otherCountry: PropTypes.string,
  nationalCardNumber: PropTypes.string,
  villageClinic: PropTypes.bool,
  isPresentFiscalYear: PropTypes.bool,
  showSewaDartaaNumber: PropTypes.bool,
  isMulDartaaNumberRequired: PropTypes.bool,
  showAsyncSelect: PropTypes.bool,
}

MulDartaaSelect.defaultProps = {
  dartaaNumberLabel: "मुल दर्ता नं.",
  sewaDartaaNumberLabel: "सेवा दर्ता नं.",
  dartaaNumberName: "mulDartaaNumber",
  patientFirstNameLabel: "सेवाग्राहीको नाम",
  patientLastNameLabel: "सेवाग्राहीको थर",
  casteCodeLabel: "जाति कोड",
  genderLabel: "लिङ्ग",
  ageLabel: "सेवाग्राहीको उमेर",
  districtLabel: "जिल्ला",
  palikaNameLabel: "नगर/गाउँपालिका",
  wardNumberLabel: "वडा नं.",
  gaunOrToleLabel: "गाँउ/टोल",
  phoneNumberLabel: "सम्पर्क नं.",
  otherCountry: "अन्य मुलुक",
  nationalCardNumber: "राष्ट्रिय परिचयपत्र नम्वर.",
  villageClinic: false,
  isPresentFiscalYear: true,
  showSewaDartaaNumber: false,
  isMulDartaaNumberRequired: true,
  showAsyncSelect: false,
}
