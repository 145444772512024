import React from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const YogSewaReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">योग सेवा प्रतिवेदन </Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="YOG-Sewa-Report">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell colSpan={2} rowSpan={2}> सेवाको विवरण</TableCell>
              <TableCell colSpan={5}>उपचारात्मक</TableCell>
              <TableCell rowSpan={2}>प्रवर्द्धनात्मक</TableCell>
              <TableCell rowSpan={2}>अभियानमूलक</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>आसन</TableCell>
              <TableCell>प्राणायाम</TableCell>
              <TableCell>ध्यान</TableCell>
              <TableCell>षट्कर्म</TableCell>
              <TableCell>अन्य </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell rowSpan={3}>सेवाग्राही संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>अन्य</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={3}>सेवा संख्या </TableCell>
              <TableCell>महिला</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुरुष</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>अन्य</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <TableContainer classes={{ root: "fixed-head-table-container" }}  >
          <Typography variant="body1" className="report-heading">प्रवर्द्धनात्मक तथा अभियानमूलक योग सेवा प्रतिबेदन</Typography>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="yog Sewa">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell colSpan={2}>सेवाको विवरण</TableCell>
                <TableCell>प्रवर्द्धधनात्मक</TableCell>
                <TableCell>अभियानमूलक</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell rowSpan={4}>सेवा ग्राही संख्या</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पुरुष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>जम्मा</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4}>सेवा पटक संख्या</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पुरुष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>जम्मा</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={2}>
        <TableContainer classes={{ root: "fixed-head-table-container" }}  >
          <Typography variant="body1" className="report-heading">उमेर अनुसार योग सेवा प्रतिबेदन</Typography>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="yog Sewa">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell>उमेर समूह/सेवा</TableCell>
                <TableCell>आसन</TableCell>
                <TableCell>प्रणायाम</TableCell>
                <TableCell>ध्यान</TableCell>
                <TableCell>षटकर्म</TableCell>
                <TableCell>अन्य</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell>० देखि ५ वर्ष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>६ देखि  १४वर्ष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>११ देखि १९माथि</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>२० देखि ५९माथि</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>६० देखि ६९माथि</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>≥ ७० बर्ष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default YogSewaReport;
