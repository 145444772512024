import { Box, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import RegisterHeader from "../../../components/register-header/RegisterHeader"
import CustomModal from "../../../components/modal/CustomModal";
import NepaliCalender from "../../../components/nepali-calender";
import CustomReactSelect from "../../../components/custom-react-select/CustomReactSelect";
import MulDartaaRegister from "../components/register/muldartaa-register/MulDartaaRegister";
import { useForm } from "react-hook-form";
import { AppMisc } from "../../../misc/appMisc";
import { AGE_UNITS, MUL_DARTA_NUMBERS_LIST, NEW_PATIENT } from "../../../utils/constants/forms";
import { CASTE_CODES, ERROR, GENDER_OPTIONS, MUL_DARTA_SEWA_TYPES, PATIENT_TYPES, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AHMIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import DeleteRegisterRecordConformation from "../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import { DateUtils } from "../../../utils/dateUtils";

const MulDartaa = () => {
  const [modalTitle, setModalTitle] = useState("मूल दर्ता रजिस्टरमा नयाँ रेकर्ड थप्नुहोस् ।");
  const [showMulDartaaModal, setShowMulDartaaModal] = useState(false);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [getTableData, setGetTableData] = useState(false);
  const [mulDartaaTableData, setMulDartaaTableData] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [showMulDartaaDeleteModal, setShowMulDartaaDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [mulDartaaDeleteId, setMulDartaaDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [mulDartaaOptions, setMulDartaaOptions] = useState();
  const [mulDartaaNumberDefaultValues, setMulDartaaNumberDefaultValues] = useState();

  const watchFields = watch("patientType", "dartaaMiti", "dartaaNumber")

  useEffect(() => {
    if (showMulDartaaModal) {
      reset()
    }
  }, [showMulDartaaModal, reset])

  useEffect(() => {
    register("district", { required: true });
    register("palikaName", { required: true });
    register("gender", { required: true });
    register("casteCode", { required: true });
    register("ageUnit", { required: true });
    register("sewaType", { required: true });
    register("patientType", { required: true });
    register("dateOfBirth", { required: true });
    register("dartaaMiti", { required: true });
  }, [register]);


  useEffect(() => {
    if (mulDartaaNumberDefaultValues) {
      setValue("dartaaNumber", mulDartaaNumberDefaultValues.dartaaNumber);
      setValue("patientFirstName", mulDartaaNumberDefaultValues.patientFirstName);
      setValue("patientLastName", mulDartaaNumberDefaultValues.patientLastName);
      if (!mulDartaaNumberDefaultValues.dateOfBirth) {
        setValue("age", mulDartaaNumberDefaultValues.age);
        setValue("ageUnit", mulDartaaNumberDefaultValues.ageUnit);
      }
      setValue("wardNumber", mulDartaaNumberDefaultValues.wardNumber);
      setValue("gaunOrTole", mulDartaaNumberDefaultValues.gaunOrTole);
      setValue("phoneNumber", mulDartaaNumberDefaultValues.phoneNumber);
      setValue("cost", mulDartaaNumberDefaultValues.cost);
      setValue("sentFromOrganizationName", mulDartaaNumberDefaultValues.sentFromOrganizationName);
      setValue("casteCode", mulDartaaNumberDefaultValues.casteCode);
      setValue("gender", mulDartaaNumberDefaultValues?.gender);
      setValue("dateOfBirth", mulDartaaNumberDefaultValues.dateOfBirth);
      setValue("parentFullName", mulDartaaNumberDefaultValues.parentFullName)
      setValue("dartaaMiti", mulDartaaNumberDefaultValues.dartaaMiti)
      setValue("age", mulDartaaNumberDefaultValues?.age)
      setValue("otherCountry", mulDartaaNumberDefaultValues?.otherCountry)
      setValue("nationalCardNumber", mulDartaaNumberDefaultValues?.nationalCardNumber)
      setModalDefaultValues(prev => ({
        ...prev,
        casteCode: mulDartaaNumberDefaultValues.casteCode,
        ageUnit: mulDartaaNumberDefaultValues.ageUnit,
        gender: mulDartaaNumberDefaultValues.gender,
        district: mulDartaaNumberDefaultValues.district,
        palikaName: AppMisc.getMunicipalityName(mulDartaaNumberDefaultValues.palikaName),
        sewaType: mulDartaaNumberDefaultValues.sewaType
      }));
    }
  }, [mulDartaaNumberDefaultValues])

  const openMulDartaaModal = () => {
    setShowMulDartaaModal(true)
    getDartaaNumber()

  }

  const closeMulDartaaModal = () => {
    reset();
    setShowMulDartaaModal(false)
    setModalDefaultValues({});
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  }

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const submitMulDartaaForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
      data.fiscalYear = DateUtils.getFiscalYearFromDateMilli(data.dartaaMiti);
    }
    AHMIS.post(API_URL.mulDartaaRegister, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeMulDartaaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const getMulDartaaData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.mulDartaaRegister}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setMulDartaaTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const mulDartaaEdit = (id) => {
    AHMIS.get(API_URL.mulDartaaRegister + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        response.data.palikaName = response.data?.palikaName && AppMisc.getMunicipalityName(response.data.palikaName);
        setShowMulDartaaModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeMulDartaaDeleteModal = () => {
    setShowMulDartaaDeleteModal(false);
    setMulDartaaDeleteId(null);
  }

  const onDeleteMulDartaaRow = (id) => {
    setShowMulDartaaDeleteModal(true);
    setMulDartaaDeleteId(id);
  }

  const deleteMulDartaaRow = (data) => {
    AHMIS.delete(API_URL.mulDartaaRegister + "/" + mulDartaaDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowMulDartaaDeleteModal(false);
          setMulDartaaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getMulDartaaData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  const handleDartaaNumberChange = (name, value, options) => {
    if (value) {
      let mulDartaaNumbers = SessionStorage.getItem(MUL_DARTA_NUMBERS_LIST);
      if (mulDartaaNumbers) {
        let muldartaaNumberInfo = mulDartaaNumbers.find(obj => obj.mulDartaaNumber === value);
        muldartaaNumberInfo ? setMulDartaaNumberDefaultValues(muldartaaNumberInfo) : getDetailsByMulDartaaNumber(options.id);
      } else {
        getDetailsByMulDartaaNumber(options.id)
      }
    }
  };

  const getDetailsByMulDartaaNumber = (id) => {
    if (id) {
      AHMIS.get(API_URL.mulDartaaDetailsFromId + "/" + id)
        .then(response => {
          let jsondata = response.data;
          if (jsondata.type === SUCCESS) {
            setMulDartaaNumberDefaultValues(response.data);
          }
        })
        .catch(error => {
          AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        });
    }
  }

  const getDartaaNumber = () => {
    let url = `${API_URL.getLatestDartaaNumber + "?fiscalYear="}` + DateUtils.getCurrentFiscalYear();
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setValue("mulDartaaNumber", jsondata?.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <RegisterHeader
        registerTitle="मूल दर्ता रजिस्टर"
        handleModalOpen={openMulDartaaModal}
        getRegisterData={getMulDartaaData}
        onHeaderParamChange={onHeaderParamChange}
        getTableData={getTableData}
        // hideButton={!Permissions?.includes(ROLE_AHMIS_MULDARTAAREGISTER_SAVE)}
        fromDateDefaultFormat
        lazyLoading
        showSearchField
      />
      <CustomModal
        title={modalTitle}
        showModal={showMulDartaaModal}
        onModalSubmit={handleSubmit(submitMulDartaaForm)}
        onModalClose={closeMulDartaaModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="mul-dartaa-dartaa-miti"
                name="dartaaMiti"
                label="दर्ता मिति"
                showDefaultDate
                onChange={handleDateChange}
                defaultDate={modalDefaultValues?.dartaaMiti}
              />
              {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="सेवाग्राहीको किसिम"
              name="patientType"
              options={PATIENT_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.patientType}
            />
            {errors?.patientType && errors.patientType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          {watchFields && watchFields.includes("PREVIOUS_PATIENT") ? (
            <Grid item xs={12} sm={6} md={3}>
              <CustomReactSelect
                label="दर्ता नम्बर"
                name="mulDartaaNumber"
                options={mulDartaaOptions}
                defaultValue={modalDefaultValues?.mulDartaaNumber}
                onChange={handleDartaaNumberChange}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="दर्ता नम्बर"
                type="number"
                size="small"
                variant="outlined"
                name="mulDartaaNumber"
                autoComplete="true"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={modalDefaultValues?.mulDartaaNumber}
                {...register('mulDartaaNumber', { required: true })}
              />
              {errors?.dartaaNumber && errors.dartaaNumber?.type === 'required' && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="patientFirstName"
              autoComplete="on"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues && modalDefaultValues?.patientFirstName}
              {...register("patientFirstName", { required: true })}
            />
            {errors?.patientFirstName && errors.patientFirstName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सेवाग्राहीको थर"
              type="text"
              size="small"
              variant="outlined"
              name="patientLastName"
              autoComplete="on"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.patientLastName}
              {...register("patientLastName", { required: true })}
            />
            {errors?.patientLastName && errors.patientLastName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="लिङ्ग"
              name="gender"
              size="small"
              fullWidth
              options={GENDER_OPTIONS}
              defaultValue={modalDefaultValues?.gender}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.gender && errors.gender?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="date-of-birth-of-child"
                label="जन्म मिति"
                name="dateOfBirth"
                defaultDate={modalDefaultValues?.dateOfBirth}
                onChange={handleDateChange}
              />
            </Box>
            {errors?.dateOfBirth && errors.dateOfBirth?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="उमेर"
              size="small"
              type="number"
              variant="outlined"
              name="age"
              autoComplete="true"
              InputLabelProps={{ shrink: true }}
              fullWidth
              defaultValue={modalDefaultValues?.age}
              {...register("age", { required: true })}
            />
            {errors?.age && errors.age?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="उमेर वर्ष वा महिना"
              name="ageUnit"
              options={AGE_UNITS}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.ageUnit}
            // isDisabled={Boolean(values.birthDate)}
            />
            {errors?.age && errors.age?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3} id="casteScrollContainer" style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="जाती"
              size="small"
              name="casteCode"
              options={CASTE_CODES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.casteCode}
            />
            {errors?.casteCode && errors.casteCode?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="जिल्ला"
              name="district"
              variant="outlined"
              onChange={handleDistrictChange}
              options={districtOptions}
              defaultValue={modalDefaultValues?.district}
            />
            {errors?.district && errors.district?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="नगर/गाउँपालिका"
              name="palikaName"
              variant="outlined"
              options={palikaOptions}
              onChange={handlePalikaNameChange}
              defaultValue={modalDefaultValues?.palikaName}
            />
            {errors?.palikaName && errors.palikaName?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="वडा नं"
              type="number"
              size="small"
              variant="outlined"
              name="wardNumber"
              autoComplete="true"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.wardNumber}
              {...register("wardNumber", { required: true })}
            />
            {errors?.wardNumber && errors.wardNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="gaunOrTole"
              label="गाँउ/टोल"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.gaunOrTole}
              {...register("gaunOrTole", { required: true })}
            />
            {errors?.gaunOrTole && errors.gaunOrTole?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="otherCountry"
              label="अन्य मुलुक"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.otherCountry}
              {...register("otherCountry")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="nationalCardNumber"
              label="राष्ट्रिय परिचयपत्र नम्वर"
              variant="outlined"
              autoComplete="on"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.nationalCardNumber}
              {...register("nationalCardNumber", { required: true })}
            />
            {errors?.nationalCardNumber && errors.nationalCardNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="सम्पर्क नम्बर"
              size="small"
              variant="outlined"
              name="phoneNumber"
              autoComplete="true"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.phoneNumber}
              {...register("phoneNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} id="scrollContainer" style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="सेवाको किसिम"
              name="sewaType"
              variant="outlined"
              options={MUL_DARTA_SEWA_TYPES}
              isMulti
              defaultValue={modalDefaultValues?.sewaType}
              onChange={handleCustomReactSelectChange}
            />
            {errors?.sewaType && errors.sewaType?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="नि:शुल्क/शुल्क रु"
              type="text"
              size="small"
              variant="outlined"
              name="cost"
              autoComplete="true"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.cost}
              {...register("cost")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रेषण भई आएको संस्थाको नाम"
              type="text"
              size="small"
              variant="outlined"
              name="sentFromOrganizationName"
              autoComplete="true"
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={modalDefaultValues?.sentFromOrganizationName}
              {...register("sentFromOrganizationName")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showMulDartaaDeleteModal}
        closeDeleteModel={closeMulDartaaDeleteModal}
        deleteRecord={deleteMulDartaaRow}
      />
      <MulDartaaRegister
        onEditRow={mulDartaaEdit}
        tableData={mulDartaaTableData}
        deleteRow={onDeleteMulDartaaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default MulDartaa;